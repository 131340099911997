import { Variant, motion } from 'framer-motion';
import { Suspense, forwardRef, lazy } from 'react';

import { environment } from '../../../../../../utils/env.utils';
import { Desktop, useIsTabletDown } from '../../../../../responsive';
import { MenuOverlay, Nav, Wrapper } from './styled';

type NavMenuAnimationVariants = Record<'visible' | 'hidden', Variant>;

const overlayVariants: NavMenuAnimationVariants = {
  visible: { opacity: 0.8 },
  hidden: { opacity: 0 }
};

const navMenuMobileVariants: NavMenuAnimationVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

const navMenuDesktopVariants: NavMenuAnimationVariants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: '-100%' }
};

const MotionMenuOverlay = motion(MenuOverlay);
const MotionNav = motion(Nav);

type Props = {
  id: string;
  transitionDuration: number;
  onItemClick: () => void;
};

const NavigationMenu = forwardRef<HTMLDivElement, Props>(({ id, transitionDuration, onItemClick }, ref) => {
  const isTabletDown = useIsTabletDown();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const DynamicNavLinks = lazy(() => import(`./NavLinks/${environment.country}.tsx`));

  return (
    <>
      <Desktop>
        <MotionMenuOverlay
          key="overlay"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: transitionDuration }}
        />
      </Desktop>

      <Wrapper id={id}>
        <MotionNav
          ref={ref}
          key="menu-wrapper"
          variants={isTabletDown ? navMenuMobileVariants : navMenuDesktopVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: transitionDuration }}
        >
          <Suspense>
            <DynamicNavLinks onItemClick={onItemClick} />
          </Suspense>
        </MotionNav>
      </Wrapper>
    </>
  );
});

NavigationMenu.displayName = 'NavigationMenu';

export default NavigationMenu;
