import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { environment } from '../utils/env.utils';

export const initializeSentry = () =>
  Sentry.init({
    dsn: 'https://c8f856b141cd11db8e8f2f0e1b95fe41@o4506580694990848.ingest.sentry.io/4506592748830720',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [environment.apiRoot],
    // Only capture error replays
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.env,
    release: environment.version
  });
