export { EmploymentStatus } from './EmploymentStatus';

export const CUSTOMER_SERVICE_EMAIL = 'kundservice@fairlo.se';

export const FINANCIAL_DETAILS_FIELDS_MAX = {
  MONTHLY_INCOME: 149999,
  MONTHLY_HOUSE_COST: 49999,
  TRANSPORTATION_COST: 19999,
  OTHER_LOAN_COST: 49999
};
