import { CUSTOMER_SERVICE_EMAIL as emailSE } from '../constants/SE';
import { CUSTOMER_SERVICE_EMAIL as emailUK } from '../constants/UK';
import { Country } from '../localization/constants';
import { environment } from '../utils/env.utils';

const emails: Record<Country, string> = {
  UK: emailUK,
  SE: emailSE
};

const MailToCustomerService = () => {
  const customerServiceEmail = emails[environment.country];

  return <a href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</a>;
};

export default MailToCustomerService;
