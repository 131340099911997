import { useTranslation } from 'react-i18next';

import { AnchorButton } from '@fairlo/ui-kit';
import marketingSiteRouteMap from '../../../../constants/marketingSiteRouteMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const WaitlistSuccess = ({ payload }: ModalProps<{ firstName: string }>) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('WaitlistSuccess.title', { firstName: payload.firstName })}>
      <ModalParagraph>{t('WaitlistSuccess.info')}</ModalParagraph>

      <Modal.Actions>
        <AnchorButton href={marketingSiteRouteMap.home}>{t('WaitlistSuccess.button')}</AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default WaitlistSuccess;
