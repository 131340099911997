import { Accordion, Colors, Heading, Text, device } from '@fairlo/ui-kit';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import AccordionStackItem from '../../AccordionStackItem';
import MailToCustomerService from '../../MailToCustomerService';
import InterestRatesTable from './InterestRatesTable';

const ListItem = styled(Text).attrs({ as: 'li' })`
  font-weight: 300;
  color: #363a3b;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10px;

  @media (${device.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const TermsAndConditionsAccordion = (props: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => (
  <Accordion {...props}>
    <AccordionStackItem label={{ value: '1. General information', as: 'h2' }} spacing={24}>
      <div>
        <Text>
          The agreement between you (“the Borrower”) and Fairlo UK Limited (“Lender”, “we”, “us”, or “Fairlo”)
          (“the Agreement”) is formed of two parts:
        </Text>
        <ol type="i">
          <ListItem>the Regulated Consumer Credit Agreement, and</ListItem>
          <ListItem>these Terms and Conditions.</ListItem>
        </ol>
      </div>

      <Text>
        Fairlo has a company number 13566128 and address Wisteria Grange Barn, Pikes End, Pinner, London,
        United Kingdom, HA5 2EX. We are regulated by the Financial Conduct Authority (Financial Services
        Register number 989159) and are registered with the Information Commissioner’s Office (Registration
        number ZB258571).
      </Text>
      <Text>
        By entering into the Agreement, the Borrower undertakes to comply with the Credit Agreement and these
        Terms and Conditions.
      </Text>
      <Text>
        A Borrower who does not understand any part of the Agreement should contact Fairlo at{' '}
        <MailToCustomerService /> for further information.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '2. Definitions', as: 'h2' }} spacing={24}>
      <Text>
        <strong>“Account”</strong> is the credit account opened by Fairlo in your name to record the amount
        the Borrower owes the Lender under your Agreement.
      </Text>
      <Text>
        <strong>“Additional Repayments”</strong> refers to repayments that the Borrower makes in addition to
        those made by Direct Debit from their Repayment Plan.
      </Text>
      <Text>
        <strong>“Borrower”</strong> refers to a natural person who has applied for an Account and has entered
        into an Agreement with the Lender.
      </Text>
      <Text>
        <strong>“Credit”</strong> refers to the amount withdrawn by the Borrower from their Account.
      </Text>
      <Text>
        <strong>“Credit Agreement”</strong> refers to the Regulated Consumer Credit Agreement.
      </Text>
      <Text>
        <strong>“Credit Assessment”</strong> refers to Fairlo’s determination of the Credit Limits for which
        you are approved. This includes an affordability assessment and a review of the Borrower’s credit
        history.
      </Text>
      <Text>
        <strong>“Credit Limit”</strong> refers to the maximum total amount of Credit that the Borrower can
        borrow at any one time.
      </Text>
      <div>
        <Text>
          <strong>“Difficult Financial Circumstance”</strong> means the occurrence of one of the events listed
          below or the occurrence of more than one of the events listed below in a series of connected events:
        </Text>
        <ul>
          <ListItem>
            you become sick, disabled or have an accident which affects your ability to make your repayments;
            or
          </ListItem>
          <ListItem>you have to stay in hospital because of a medical condition; or</ListItem>
          <ListItem>you have to attend jury service; or</ListItem>
          <ListItem>your main home is significantly damaged by a natural disaster e.g. flooding; or</ListItem>
          <ListItem>
            your partner (who you have lived with for more than 6 months) or an immediate family member
            (sibling, parent, spouse or child) dies.
          </ListItem>
        </ul>
      </div>
      <Text>
        <strong>“Direct Debit”</strong> is the mechanism your monthly repayment is made. This is processed
        through the BACs Direct Debit scheme.
      </Text>
      <Text>
        <strong>“Fairlo Account”</strong> refers to the Fairlo website where you can login and manage your
        Account online.
      </Text>
      <Text>
        <strong>“Interest Charges”</strong> is the interest accrued on the Outstanding Balance.
      </Text>
      <Text>
        <strong>“Lender”, “we”, “us”, or “Fairlo”</strong> refers to Fairlo UK Limited with company number
        13566128 and address Wisteria Grange Barn, Pikes End, Pinner, London, United Kingdom, HA5 2EX.
      </Text>
      <Text>
        <strong>“Monthly Repayment”</strong> refers to the monthly amount that the Borrower must repay in
        accordance with the agreed Repayment Plan
      </Text>
      <Text>
        <strong>“Nominated Account”</strong> is the UK bank or building society account in your name that you
        can make repayments by direct debit and receive withdrawals into.
      </Text>
      <Text>
        <strong>“Outstanding Balance”</strong> is the total unpaid amount on your Account.
      </Text>
      <Text>
        <strong>“Personal Data”</strong> is the personal information relating to you that we process.
      </Text>
      <Text>
        <strong>“Repayment Plan”</strong> refers to the schedule of monthly repayments to repay the
        Outstanding Balance on your Account.
      </Text>
      <Text>
        <strong>“Security Details”</strong> are the username and one time passwords, the information on the
        Account which is personal to the Borrower, and any other information or procedure that can be used for
        authorising a transaction.
      </Text>
      <Text>
        <strong>“Statement”</strong> is the monthly summary you will receive showing the Credit Limit, the
        Outstanding Balance, Interest Charges and any Transactions during that period.
      </Text>
      <Text>
        <strong>“Terms and Conditions”</strong> means this document.
      </Text>
      <Text>
        <strong>“Transactions”</strong> refer to Withdrawals, Monthly Repayments and any Additional Repayments
        initiated by the Borrower.
      </Text>
      <Text>
        <strong>“Withdrawal”</strong> refers to the transfer of money from the Lender’s Account to the
        Borrower’s Nominated Account.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: "3. About Fairlo's services", as: 'h2' }} spacing={24}>
      <Text>
        Fairlo provides unsecured running-account credit that gives the Borrower the right to continuously
        access a Credit Limit during the life of the Agreement. The Account will have a Credit Limit for which
        the Borrower is approved following an online application at{' '}
        <a href="https://fairlo.co.uk">www.fairlo.co.uk</a>. The Borrower can withdraw funds from their
        Account to their Nominated Account at any time, provided that the Credit Limit is not exceeded. The
        Credit can only be transferred to the Borrower’s Nominated Account.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '4. Borrower eligibility requirements', as: 'h2' }} spacing={24}>
      <div>
        <Text>To be eligible for an Account you must:</Text>
        <ul>
          <ListItem>be a natural person over 20 years old</ListItem>
          <ListItem>have a declared annual income of at least £20,000</ListItem>
          <ListItem>
            be a current resident in the UK (excluding Northern Ireland) for a minimum of 12 months
          </ListItem>
          <ListItem>
            have a UK bank or building society account able to make repayments by Direct Debit
          </ListItem>
          <ListItem>
            not have any County Court Judgments (CCJs) or Debt Relief Orders against you and/ or be subject to
            an Individual Voluntary Arrangement (IVA)
          </ListItem>
        </ul>
      </div>
      <Text>
        You must let us know of anything you are aware of which may result in a deterioration in your
        financial circumstances by contacting our team at <MailToCustomerService />.
      </Text>
      <div>
        <Text>
          You cannot intend to use withdrawals from your Fairlo Account for any of the following activities:
        </Text>
        <ul>
          <ListItem>Speculative investments e.g. gambling or share purchase</ListItem>
          <ListItem>As a deposit for the purchase of land or property</ListItem>
          <ListItem>Illegal activities</ListItem>
        </ul>
      </div>
    </AccordionStackItem>

    <AccordionStackItem
      label={{ value: '5. Your credit application & credit reference agencies', as: 'h2' }}
      spacing={24}
    >
      <Text>
        We will perform a Credit Assessment based on your Personal Data, credit record, and additional
        information you have provided throughout the lifetime of the Agreement.
      </Text>
      <Text>
        When you apply, we’ll perform credit and identity checks on you with credit reference agencies. When
        we carry out a search at the credit reference agencies, they will place a soft footprint on your
        credit file, this has no effect on your credit score, and other organisations are unable to see this.
      </Text>
      <Text>
        When you open an Account by signing the Agreement, there will be a hard footprint, this footprint will
        be viewable by other organisations and may affect your ability to get credit elsewhere.
      </Text>
      <Text>
        We will continue to exchange information about you with credit reference agencies while you have a
        relationship with us. If you do not keep up with your minimum repayments, they will record the
        outstanding debt on your credit file and in turn supply this to other organisations. Payment records
        remain on your credit file for 6 years after they are closed, whether settled by you or defaulted.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '6. Withdrawals', as: 'h2' }} spacing={24}>
      <Text>
        You can make a Withdrawal from your Account by accessing your Fairlo Account at{' '}
        <a href="https://fairlo.co.uk">www.fairlo.co.uk</a>. Any Withdrawal is subject to available credit on
        your account. By this we mean the amount of your credit limit remaining.
      </Text>
      <Text>
        All Withdrawals are immediately transferred electronically (in UK pounds sterling) from your Account,
        and should land in your nominated account within 2 hours (typically much faster).
      </Text>
      <Text>
        We may, to protect you against fraud, apply limits to how much you can transfer from your Account,
        either in one payment or per day.
      </Text>

      <Heading as="h3" size="s">
        <strong>6.1 Withdrawal refusal</strong>
      </Heading>
      <div>
        <Text>We will refuse to carry out a Withdrawal request from your Account if:</Text>
        <ol type="a">
          <ListItem>You are not up to date with your repayments, or</ListItem>
          <ListItem>The withdrawal would take your Outstanding Balance over your Credit Limit.</ListItem>
        </ol>
      </div>

      <div>
        <Text>
          We may also refuse to carry out a Withdrawal or, if it is reasonable, put a temporary or permanent
          stop on you using the Account:
        </Text>
        <ol type="a">
          <ListItem>To protect the security of your Account</ListItem>
          <ListItem>
            To stop the Account or its security details being used fraudulently, or for other criminal or
            illegal purposes
          </ListItem>
          <ListItem>
            To reflect or prevent a significant increase in the risk that you may not be able to pay us what
            you owe
          </ListItem>
          <ListItem>
            If we reasonably believe that the information you gave us in your application is untrue
          </ListItem>
          <ListItem>If we are unable to contact you</ListItem>
          <ListItem>If you have told us that someone else knows your security details</ListItem>
        </ol>
      </div>
      <Text>
        If we refuse to carry out a Withdrawal request, we will tell you and, unless prevented by law, will
        explain why we are doing so.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '7. How we charge interest', as: 'h2' }} spacing={24}>
      <Text>
        Interest is calculated and charged daily on your Outstanding Balance at the end of the day. The
        applicable interest rate shall be identified on your agreed Repayment Plan and within your Fairlo
        Account.
      </Text>
      <Text>
        The interest charges will not extend the duration of your Repayment Plan, or increase the Monthly
        Repayment Amount as long as you keep up repayments.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '8. Repayment plan', as: 'h2' }} spacing={24}>
      <div>
        <Text>
          Your Repayment Plan outlines the amount and date of your regular monthly repayments which need to be
          made. Your Repayment Plan is updated when you:
        </Text>
        <ol>
          <ListItem>Make a withdrawal</ListItem>
          <ListItem>Make an additional repayment</ListItem>
          <ListItem>Change the amount of your repayment plan</ListItem>
        </ol>
      </div>
      <Text>
        You will receive your Repayment Plan via email whenever it’s updated, and you can always view your
        current Repayment Plan at any time from your Fairlo Account.
      </Text>

      <Heading as="h3" size="s">
        <strong>8.1 Your minimum repayment</strong>
      </Heading>
      <Text>
        Your minimum repayment will be determined at our discretion based on your current Outstanding Balance,
        APR and maximum repayment duration.
      </Text>
      <Text>
        We do this to ensure that you always have a repayment plan that repays your Outstanding Balance and
        interest within a reasonable and predictable timeframe and cost.
      </Text>

      <Heading as="h3" size="s">
        <strong>8.2 How your monthly repayment amount is determined</strong>
      </Heading>
      <Text>
        When you make your first withdrawal we will set the required amount to be repaid each month by Direct
        Debit at the minimum repayment.
      </Text>
      <Text>
        You can make additional repayments at any time through your Fairlo Account to reduce the cost of
        credit.
      </Text>
      <Text>
        You can increase the amount you will pay each month from the minimum at any time through your Fairlo
        Account.
      </Text>
      <Text>If you have an Outstanding Balance of zero then no repayments are due.</Text>

      <Heading as="h3" size="s">
        <strong>8.3 How your monthly repayment date is determined</strong>
      </Heading>
      <Text>
        You will select your preferred date for these repayments to be made when setting up your Nominated
        Account, and this can be changed from your Fairlo Account.
      </Text>
      <Text>
        If it isn’t present in a particular month then the repayment date for that month will be the last date
        of that month. i.e. 31st becomes 30th in April.
      </Text>
      <Text>
        Where your preferred date falls on a weekend or, bank holiday then the repayment will be made the next
        business day.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '9. How to make repayments', as: 'h2' }} spacing={24}>
      <Text>
        You are responsible for repaying the Outstanding Balance on your Account under your Agreement.
      </Text>
      <div>
        <Text>There are two ways to repay your Outstanding Balance:</Text>
        <ol>
          <ListItem>Regular Monthly Repayments in accordance with your Repayment Plan; and</ListItem>
          <ListItem>Additional Repayments</ListItem>
        </ol>
      </div>
      <Text>All payments must be made in UK pounds sterling from your Nominated Account.</Text>

      <Heading as="h3" size="s">
        <strong>9.1 Regular monthly repayments</strong>
      </Heading>
      <Text>
        Your regular Monthly Repayments will be taken via Direct Debit from your Nominated Account as per your
        Repayment Plan.
      </Text>
      <Text>Any Additional Repayments do not replace your regular monthly repayments.</Text>
      <Text>If you have an Outstanding Balance of zero then no repayments are due.</Text>

      <Heading as="h3" size="s">
        <strong>9.2 Additional repayments</strong>
      </Heading>
      <Text>
        You can make Additional Repayments from your Nominated Account for part or all of the Outstanding
        Balance. They can be made as often as you would like with no fees or charges. You can do this from
        your Fairlo Account at <a href="https://fairlo.co.uk">www.fairlo.co.uk</a>.
      </Text>
      <Text>
        Any Additional Repayments do not replace your regular monthly repayments which will still be due in
        accordance with the Repayment Plan.
      </Text>
      <Text>
        Any repayments made from an account different to your Nominated Account will be immediately returned
        to the account they came from.
      </Text>
      <Text>
        We would always encourage you to repay your Outstanding Balance as early as possible to reduce the
        total cost of credit, however you should ensure that this is sustainable and won’t cause you to have
        financial difficulties.
      </Text>
      <Text>
        If you settle the full outstanding balance, then you will also pay the interest which has accrued
        since your last regular Monthly Repayment to the date of the Additional Repayment.
      </Text>
      <Text>
        If you repay more than you owe, the excess amount will be immediately returned to your Nominated
        Account.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem
      label={{ value: '10. What happens if you miss a monthly repayment', as: 'h2' }}
      spacing={24}
    >
      <Text>
        The first time your monthly Direct Debit attempt is unsuccessful, then we will update your repayment
        plan to the minimum monthly repayment and retry the Direct Debit.
      </Text>
      <Text>
        If the Direct Debit retry is unsuccessful, we will notify you and ask you to login to your Fairlo
        Account to make a manual repayment.
      </Text>

      <Heading as="h3" size="s">
        <strong>10.1 Consequences of missing repayments</strong>
      </Heading>
      <Text>
        Daily interest will accrue on the Outstanding Balance from any missed repayments, meaning you will owe
        more interest than your Repayment Plan.
      </Text>
      <Text>
        Missing your minimum repayment could have severe consequences. This could include the withdrawal of
        your credit line, referral to our collections team (who may issue legal proceedings), or refer your
        debt to an external debt collection agency (which may attempt to contact you regarding the balance, or
        instigate legal proceedings).
      </Text>
      <Text>
        Missed repayments may be reported to credit reference agencies which could make obtaining credit more
        difficult and/or more expensive due to the impact on your credit record.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '11. Statements', as: 'h2' }} spacing={24}>
      <Text>
        Your monthly Statement contains details of your Outstanding Balance, Credit Limit, Transactions and
        Interest Charges on your Account during the relevant period. We will provide your statement each month
        either within your Fairlo Account or via email.
      </Text>
      <Text>
        If you think that anything shown in your Statement is incorrect, you should tell us immediately by
        contacting our customer service team using the contact details given at the start of your Agreement.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '12. Difficult financial circumstances', as: 'h2' }} spacing={24}>
      <Text>
        If you experience Difficult Financial Circumstances as defined in the glossary, and you request us to
        freeze your account by contacting us, we will, subject to the terms and conditions set out below,
        freeze your Account for as long as the difficult financial circumstance continues to apply, up to a
        maximum period of 12 months per event (or series of connected events).
      </Text>
      <div>
        <Text>If we freeze your Account that means that:</Text>
        <ul>
          <ListItem>You will not be able to make any further Withdrawals from your Account;</ListItem>
          <ListItem>We will not charge you interest on any Outstanding Balance under your Account;</ListItem>
          <ListItem>
            You will not be obliged to make any repayments under your Agreement (though, if you wish, you can
            make voluntary repayments to reduce the Outstanding Balance on your Account);
          </ListItem>
          <ListItem>
            We will contact you at least every 6 months to review your individual circumstances while your
            Account remains frozen.
          </ListItem>
        </ul>
      </div>
      <Text>
        After the Difficult Financial Circumstance ceases to apply or after 12 months (whichever is sooner),
        you will be able to use your Account, interest will start accruing again and you will be obliged to
        continue your repayments under your Agreement.
      </Text>
      <Text>
        We will only freeze your Account if you provide us with reasonable evidence to show you first
        experienced the Difficult Financial Circumstance after you made Withdrawals. You must also continue to
        supply us, on request by us, with reasonable evidence of the continuation of the Difficult Financial
        Circumstance at any time while your Account is frozen. If you do not do so, we may terminate the
        freezing of your Account.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem
      label={{ value: '13. When we may make changes to your agreement', as: 'h2' }}
      spacing={24}
    >
      <Text>
        As the Agreement has no fixed end date, we may need to make changes to it to take account of changes
        affecting our business or changes affecting your financial status. If you are unhappy with any changes
        we make, you can end your Agreement at any time by repaying your Outstanding Balance (including, for
        the avoidance of any doubt, any interest that has accrued up to this point) in full and choosing to
        end the Agreement through your Fairlo Account at <a href="https://fairlo.co.uk">www.fairlo.co.uk</a>.
      </Text>

      <Heading as="h3" size="s">
        <strong>13.1 Changes to the credit limit</strong>
      </Heading>
      <div>
        <Text>
          We will review your Credit Limit on an ongoing basis and may change it from time to time to respond
          proportionately to:
        </Text>
        <ul>
          <ListItem>
            A change in the risk of you breaking your Agreement, having regard to how you have been making
            repayments on your account;
          </ListItem>
          <ListItem>A change in your financial status; or</ListItem>
          <ListItem>A change in the value of money.</ListItem>
        </ul>
      </div>
      <div>
        <Text>
          We will normally give you at least 30 days’ notice of a reduction in your Credit Limit. However, we
          may reduce your Credit Limit without notice (in which case we will tell you about the reduction as
          soon as possible afterwards) where:
        </Text>
        <ul>
          <ListItem>
            We reasonably think that the reduction is needed to stop you carrying out further Withdrawals that
            would increase your Outstanding Balance to an amount that you cannot afford to repay; or
          </ListItem>
          <ListItem>
            There is a substantial change for the worse in your financial status or your ability to afford the
            repayments you must make to us under your Agreement; or
          </ListItem>
          <ListItem>We need to make the reduction to comply with legal or regulatory requirements.</ListItem>
        </ul>
      </div>
      <Text>
        You may ask us to reduce your Credit Limit at any time. However, we will not reduce your Credit Limit
        to a level below your Outstanding Balance.
      </Text>
      <Text>
        You may be able to apply for a higher Credit Limit at any time on your Fairlo Account. If you do so we
        may perform a creditworthiness and affordability assessment to confirm your eligibility.
      </Text>

      <Text style={{ border: `1px solid ${Colors.GRAY_900}`, padding: '4px' }}>
        WARNING: A higher Credit Limit may offer greater flexibility, more convenience and a lower cost of
        credit to meet your financial needs. However, it may also increase the risk of taking on too much
        debt, which may be costly, or take a long time to pay off. You should carefully consider whether you
        need a higher limit.
      </Text>

      <Heading as="h3" size="s">
        <strong>13.2 Changes to interest rates </strong>
      </Heading>

      <div>
        <Text>
          Your interest rate is linked to the Bank of England Base Rate and Fairlo has the right to reflect
          any changes to this in your interest rate immediately.
        </Text>
        <Text>
          We will give you at least 30 days’ notice of any increase in your interest rates due to other
          factors referenced below:
        </Text>
        <ul>
          <ListItem>A change beyond our reasonable control in the cost of providing your Account;</ListItem>
          <ListItem>
            A change in the cost to us of funding the provision of credit under your Agreement;
          </ListItem>
          <ListItem>
            A change in legal or regulatory requirements or in any code of practice affecting our business; or
          </ListItem>
          <ListItem>
            A change in the cost to us of complying with any legal or regulatory requirements that apply to us
            (but excluding any additional costs we have to bear as a result of a failure by us to comply with
            those requirements).
          </ListItem>
        </ul>
      </div>
      <Text>
        Where we decrease your interest rates, we will notify you before the variation takes effect.
      </Text>
      <div>
        <Text>
          If we give you notice of an increase in your interest rates, you will have 30 days, starting with
          the date when you receive the notice, in which to tell us that you reject the increase. If you do
          this, the increase will not apply to you but:
        </Text>
        <ul>
          <ListItem>No further Withdrawals may be carried out;</ListItem>
          <ListItem>You must immediately repay all the money you owe under your Agreement;</ListItem>
          <ListItem>
            Your Agreement will end and your Account will be closed when all the money you owe has been
            repaid.
          </ListItem>
        </ul>
      </div>

      <Heading as="h3" size="s">
        <strong>13.3 Changes to the other terms of your agreement</strong>
      </Heading>
      <div>
        <Text>
          We may from time to time make changes to the other Terms and Conditions of your Agreement for any
          one or more of the following reasons:
        </Text>
        <ul>
          <ListItem>to correct an error;</ListItem>
          <ListItem>to make the terms easier for you to understand;</ListItem>
          <ListItem>
            to enable us to offer you a new service or feature in connection with your account;
          </ListItem>
          <ListItem>
            to reflect improvements in the systems we use to run our business (including changes in the
            capabilities of those systems) or other changes to those systems which are beyond our reasonable
            control;
          </ListItem>
          <ListItem>
            to reflect changes in technology, fraud prevention requirements or payment methods;
          </ListItem>
          <ListItem>
            to reflect changes beyond our reasonable control in the costs of providing the services we supply
            to you;
          </ListItem>
          <ListItem>
            to reflect a change which has occurred or we reasonably expect to occur in the near future in
            legal or regulatory requirements or in the requirements of any trade body that apply to us;
          </ListItem>
          <ListItem>
            to reflect the requirements of any code of practice which promotes good practice in the financial
            services sector.
          </ListItem>
        </ul>
      </div>
      <Text>
        We will ensure that any change we make to the Terms and Conditions of your Agreement is a
        proportionate response to the reason relied on for making the change.
      </Text>
      <Text>
        We will give you at least 30 days’ notice of any change we make to the Terms and Conditions of your
        Agreement, except that, where the change is in your favour, we may make it on giving you not less than
        7 days’ notice.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '14. Communicating with you', as: 'h2' }} spacing={24}>
      <div>
        <Text>We may give you a notice or document under your Agreement:</Text>
        <ul>
          <ListItem>by posting or delivering it to the last contact address you have given us;</ListItem>
          <ListItem>
            by sending it as an attachment to a message sent to the latest email address or mobile number
            which you have given us; or
          </ListItem>
          <ListItem>by providing it through your Fairlo Account at www.fairlo.co.uk.</ListItem>
        </ul>
      </div>
      <Text>
        Any attachment to a message we send you will be a PDF or in another format which allows you to store
        it for future reference.
      </Text>
      <Text>
        Any telephone calls between you and us may be monitored and might be recorded to enable us to maintain
        quality control, check that Transactions have been correctly processed and help with the resolution of
        disputes and the prevention of fraud.
      </Text>
      <Text>
        We will communicate with you in English and all contract terms and information supplied to you will be
        in English.
      </Text>
      <Text>
        You have the right at any time while your Agreement remains in force to ask us to provide you with the
        terms of your Agreement (which contains the information we were required by law to provide to you
        before you became bound by your Agreement).
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '15. Communicating with us', as: 'h2' }} spacing={24}>
      <div>
        <Text>You must tell us tell us immediately if:</Text>
        <ul>
          <ListItem>you change your name or address;</ListItem>
          <ListItem>you change your mobile number or your email address;</ListItem>
          <ListItem>your income and outgoings materially change;</ListItem>
          <ListItem>you move overseas;</ListItem>
          <ListItem>your statement includes any information that you think is wrong;</ListItem>
          <ListItem>you believe your security details have been stolen or have been misused.</ListItem>
        </ul>
      </div>
      <Text>
        You must let us know if your circumstances change, particularly if the change is likely to cause you
        difficulties in making your repayments or general financial problems.
      </Text>
      <Text>
        If you have moved overseas or you ask us to write to you using a Post Office Box address, we may close
        your account. If we decide to do this we will give you notice in line with section 19.
      </Text>
      <Text>
        You can tell us about these changes by contacting our team at <MailToCustomerService />
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '16. Account security', as: 'h2' }} spacing={24}>
      <div>
        <Text>
          You must take all reasonable precautions to keep your security details safe. This includes:
        </Text>
        <ul>
          <ListItem>
            keeping your mobile secure to prevent unauthorised use of the SMS one time password;
          </ListItem>
          <ListItem>not allowing anyone else to access your email</ListItem>
          <ListItem>not allowing anyone else access to your Nominated Account</ListItem>
          <ListItem>not allowing anyone else to use your Fairlo Account details;</ListItem>
        </ul>
      </div>
      <div>
        <Text>You must contact us immediately if:</Text>
        <ul>
          <ListItem>you suspect that someone else knows your Fairlo Account security details; or</ListItem>
          <ListItem>
            you suspect that someone has carried out an unauthorised Transaction on your account, or may try
            to do so.
          </ListItem>
        </ul>
      </div>
      <Text>
        CONTACT US BY EMAIL: <MailToCustomerService />
      </Text>
      <Text>
        If we become aware of suspected or actual fraud or security threats relating to your Account or
        security details, we will attempt to contact you by SMS (using the number you have given us), or email
        asking you to contact us. Provided that you satisfactorily complete the security checks we use to
        verify your identity, then (to the extent permitted by law) we will inform you of the nature of the
        suspected or actual fraud or security threats.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '17. Processing personal data', as: 'h2' }} spacing={24}>
      <Text>
        The Fairlo Privacy Notice tells you how we use your personal data, describes the categories of
        Personal Data we process and for what purposes. You can read the Fairlo Privacy Notice at{' '}
        <a href="https://fairlo.co.uk/privacy">www.fairlo.co.uk/privacy</a>.
      </Text>
      <Text>
        This includes how we make decisions affecting you and your Account, how we market to you, with whom we
        share your information, and how long we keep it.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '18. Transferring your agreement', as: 'h2' }} spacing={24}>
      <Text>You cannot transfer your Agreement or your rights and duties under it.</Text>
      <div>
        <Text>
          We may transfer your Agreement, or any of our rights and duties under it, to another person or
          organisation (“the Transferee”) if:
        </Text>
        <ul>
          <ListItem>
            The Transferee is authorised by the Prudential Regulation Authority or the Financial Conduct
            Authority and has permission from the Financial Conduct Authority to exercise the lender’s rights
            under agreements regulated by the Consumer Credit Act 1974;
          </ListItem>
          <ListItem>
            We have first agreed a statement of policy with the Transferee under which they agree to exercise
            the rights and powers transferred in a way that is no less favourable to you than the way in which
            we have been exercising them before the transfer.
          </ListItem>
        </ul>
      </div>
      <Text>
        We will arrange for notice of the transfer to be given to you as soon as reasonably possible after the
        transfer. However, if there is no change so far as you are concerned in the arrangements for
        administering your Agreement following the transfer, we do not need to give you notice of the transfer
        until those arrangements are changed in a way which affects you.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '19. Ending your agreement', as: 'h2' }} spacing={24}>
      <Text>
        You can end your Agreement at any time by repaying your Outstanding Balance in full and choosing to
        end the Agreement through your Fairlo Account.
      </Text>
      <Text>We may end your Agreement at any time by giving you not less than 2 months’ notice.</Text>
      <div>
        <Text>We can also give you notice to end your Agreement immediately in the following cases:</Text>
        <ol type="a">
          <ListItem>
            you have broken your Agreement regularly or seriously i.e. missed payments or suspected fraud;
          </ListItem>
          <ListItem>
            a bankruptcy order, debt relief order or similar order relating to personal insolvency is made
            against you or you enter into an individual voluntary arrangement;
          </ListItem>
          <ListItem>we are unable to contact you for more than 6 months;</ListItem>
          <ListItem>we have put a permanent stop on you using your Account; or</ListItem>
          <ListItem>you behave in a manner which is threatening or abusive to our staff.</ListItem>
        </ol>
      </div>
      <Text>
        We will only give you notice to end your Agreement for the reason given in (a) above if we have first
        given you a default notice under the Consumer Credit Act 1974 and the default notice has expired
        without you having remedied the breach (if it was capable of being remedied).
      </Text>
      <Text>
        If you or we have given notice to end your Agreement you must repay all the money you owe us. The
        Agreement will not end formally until you have repaid all the money you owe us under it.
      </Text>
      <Text>
        Should you die, then your Agreement will end immediately and does not transfer to anyone else.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '20. Limitation of liability', as: 'h2' }} spacing={24}>
      <Text>
        Fairlo is not liable for damages resulting from law enforcement, government action, war event, strike,
        lockout, blockade, boycott or any other circumstance beyond the Fairlo’s control. The proviso
        regarding strikes, lockouts, blockades and boycotts applies even if Fairlo itself is the subject of or
        takes such a conflict measure. Damage that may arise in other cases shall not be compensated if the
        Lender has acted with normal care. In no case shall Fairlo be liable for indirect damages.
      </Text>
      <Text>
        If there is an obstacle preventing Fairlo from making payment or taking another action due to
        circumstances stated above, the action may be postponed until the obstacle has ceased. This also
        applies for technical errors and delays that might occur, independent of whether these are beyond
        Fairlo’s control or not.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '21. Other', as: 'h2' }} spacing={24}>
      <Text>
        Even if one clause or part of a clause in your Agreement is found to be invalid or unenforceable,
        everything else still applies.
      </Text>
      <Text>
        Even if we don&apos;t enforce any of our rights straight away, that doesn&apos;t mean we can&apos;t
        later on.
      </Text>
      <Text>
        Anything we&apos;ve written in the singular includes the plural – and vice versa (so
        &apos;Repayment&apos; includes &apos;Repayments&apos;, for instance).
      </Text>
      <Text>
        None of the section headings in your Agreement affect the meaning of the terms in the section.
      </Text>
      <Text>
        Your Agreement is between you and us. No one else has the legal right to enforce any of the terms
        under the Contracts (Rights Of Third Parties) Act 1999.
      </Text>
      <Text>
        Your Agreement is entered into by Fairlo on the basis that the details you provided as part of your
        application are true and accurate to the best of your knowledge.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '22. Who supervises us', as: 'h2' }} spacing={24}>
      <Text>
        We are regulated by the Financial Conduct Authority (Financial Services Register Number 989159). The
        Financial Conduct Authority is the supervisory authority under the Consumer Credit Act 1974 and its
        address is 12 Endeavour Square, Stratford, London E20 1JN.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '23. Complaints', as: 'h2' }} spacing={24}>
      <Text>
        If you have a complaint about the service we provide, please contact us at{' '}
        <a href="mailto:complaints@fairlo.co.uk">complaints@fairlo.co.uk</a>. We can provide you with a copy
        of our internal complaints handling procedure on request, or you can find more details via the
        ‘contact us’ page of our website, <a href="https://fairlo.co.uk/contact">www.fairlo.co.uk/contact</a>.
      </Text>
      <Text>
        If we cannot resolve a complaint to your satisfaction, you can refer it to the Financial Ombudsman
        Service by writing to them at Exchange Tower, London E14 9SR, telephoning them on 0800 023 4 567 or
        filling in the online complaint form on their website (
        <a href="https://www.financial-ombudsman.org.uk/">www.financial-ombudsman.org.uk/</a>). You will not
        be able to refer your complaint to the Financial Ombudsman Service unless you have taken it up with us
        first.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: '24. Governing law', as: 'h2' }} spacing={24}>
      <Text>
        Your Agreement is governed by the law of England and Wales, except that, if you applied for your
        Agreement from an address in Scotland or Northern Ireland, the law of Scotland or Northern Ireland
        will apply. Any dispute under your Agreement may be dealt with by the courts of England and Wales
        unless your address is in Scotland or Northern Ireland, in which case the courts of Scotland or
        Northern Ireland will apply.
      </Text>
    </AccordionStackItem>

    <AccordionStackItem label={{ value: 'APPENDIX A - Interest Rates', as: 'h2' }} spacing={24}>
      <Text>
        The Lender has the right to change the interest rates stated below in accordance with what is stated
        in these Terms and Conditions. The Lender is only entitled to change the interest rate in accordance
        with clause 13.2.
      </Text>
      <Text>
        The Borrower only pays interest on their Outstanding Balance and there are no other fees or charges.
      </Text>
      <InterestRatesTable />
    </AccordionStackItem>
  </Accordion>
);

export default TermsAndConditionsAccordion;
