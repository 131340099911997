import { useDevCycleClient } from '@devcycle/react-client-sdk';
import { useCallback } from 'react';
import { trackUser } from '../analytics';
import { reportException } from '../utils/error';

const useIdentifyUser = () => {
  const devCycleClient = useDevCycleClient();

  return useCallback(
    ({ id, email }: { id: string; email?: string }) => {
      void Promise.all([
        trackUser({ id, email }),
        devCycleClient.identifyUser({
          user_id: id,
          email
        })
      ]).catch(error => {
        reportException(error, {
          level: 'warning'
        });
      });
    },
    [devCycleClient]
  );
};

export default useIdentifyUser;
