import { Colors, device } from '@fairlo/ui-kit';
import styled, { css } from 'styled-components';

export const zIndex = {
  CHAT_WIDGET: 9,
  COOKIE_BANNER: 10,
  HEADER: 20,
  FULL_SCREEN_LOADER: 100,
  CHAT_WIDGET_OPEN: 9999
};

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--layout-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--layout-horizontal-padding);
  padding-right: var(--layout-horizontal-padding);
`;

export const ButtonText = styled.button<{ $hasAnchorStyles?: boolean }>`
  border: none;
  background: none;
  color: ${({ $hasAnchorStyles }) => ($hasAnchorStyles ? 'inherit' : Colors.GRAY_400)};
  font-weight: ${({ $hasAnchorStyles }) => $hasAnchorStyles && 'inherit'};
  font-size: inherit;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
`;

const fullScreenStyles = css`
  background: ${Colors.WHITE};
  position: fixed;
`;

const absoluteStyles = css`
  position: absolute;
`;

export const CenteredWrapper = styled.div<{ $fullscreen: boolean }>`
  ${({ $fullscreen }) => ($fullscreen ? fullScreenStyles : absoluteStyles)};
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.FULL_SCREEN_LOADER};
`;

export const GridItem = styled.div`
  padding: 18px 0;
  display: flex;
  align-items: center;
  box-shadow: 0 -1px 0 0 inset ${Colors.GRAY_100};

  // First column
  &:nth-child(3n + 1) {
    padding-left: 24px;

    @media (${device.tablet}) {
      padding-left: 0;
    }
  }

  // Second column
  &:nth-child(3n + 2) {
    justify-content: flex-start;
  }

  // Third column
  &:nth-child(3n + 3) {
    padding-right: 24px;

    @media (${device.tablet}) {
      padding-right: 0;
    }
  }
`;

// Button to be used along with icon/span/div etc. for accessibility
export const DiscreetButton = styled.button.attrs({ type: 'button' })`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const TermsWrapper = styled.div`
  max-width: 320px;
  text-align: center;
  margin-bottom: 8px;

  @media (${device.tablet}) {
    text-align: start;
  }
`;

export const FullWidthContainer = styled.div`
  margin: 0 -24px;

  @media (${device.tablet}) {
    margin: 0;
  }
`;

// Screen readers only
export const SROnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export const nativeFocusOutlineStyles = css`
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
`;

// Keeps the digits the same size so the text doesn't shift as they change
export const retainWidthStyles = css`
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
`;

export const MobileOnly = styled.div`
  @media (${device.tablet}) {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  @media (${device.tablet}) {
    display: block;
  }
`;
