import { Colors } from '@fairlo/ui-kit';
import { AnimatePresence } from 'framer-motion';
import { Cross as Hamburger } from 'hamburger-react';
import { useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '../../../../analytics';
import logotypeImage from '../../../../assets/images/fairlo-logotype-green.svg';
import { AnalyticsEvents } from '../../../../constants/analytics';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { routeMap } from '../../../../routes/routeMap';
import NavigationMenu from './components/NavigationMenu';
import useScrollLock from './hooks/useScrollLock';
import { Content, LogotypeImage, LogotypeLink, Wrapper } from './styled';

const TRANSITION_DURATION = 400; // ms
const transitionDurationInSeconds = TRANSITION_DURATION / 1000;

const navMenuId = 'nav-menu';

const Header = () => {
  const { t } = useTranslation(['Header', 'Common']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const navigationMenuRef = useRef<HTMLDivElement>(null);

  useScrollLock(isMenuOpen, TRANSITION_DURATION);
  useOnClickOutside(() => setIsMenuOpen(false), [headerRef, navigationMenuRef], { enabled: isMenuOpen });

  return (
    <FocusLock disabled={!isMenuOpen}>
      <Wrapper ref={headerRef} $isMenuOpen={isMenuOpen}>
        <Content>
          <LogotypeLink to={routeMap.home} aria-label="Fairlo">
            <LogotypeImage src={logotypeImage} alt="" />
          </LogotypeLink>

          <Hamburger
            size={34}
            rounded
            hideOutline={false}
            color={Colors.BRAND_PRIMARY}
            toggled={isMenuOpen}
            label={t(isMenuOpen ? 'closeMenu' : 'openMenu')}
            toggle={value => {
              setIsMenuOpen(value);
              void trackEvent(
                value ? AnalyticsEvents.NAVIGATION_MENU_OPENED : AnalyticsEvents.NAVIGATION_MENU_CLOSED
              );
            }}
            duration={transitionDurationInSeconds}
            aria-expanded={isMenuOpen}
            aria-controls={navMenuId}
          />
        </Content>
      </Wrapper>

      <AnimatePresence>
        {isMenuOpen ? (
          <NavigationMenu
            ref={navigationMenuRef}
            id={navMenuId}
            transitionDuration={transitionDurationInSeconds}
            onItemClick={() => setIsMenuOpen(false)}
          />
        ) : null}
      </AnimatePresence>
    </FocusLock>
  );
};

export default Header;
