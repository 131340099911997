import { z } from 'zod';

const environmentSchema = z
  .object({
    VITE_VERSION: z.string(),
    VITE_ENV: z.enum(['local', 'develop', 'test', 'production']),
    VITE_API_ROOT: z.string().url(),
    VITE_SEGMENT_TOKEN: z.string(),
    VITE_FRESHDESK_KEY: z.string(),
    VITE_LOQATE_KEY: z.string(),
    VITE_DVC_SDK_KEY: z.string(),
    VITE_MARKETING_SITE_URL: z.string().url(),
    VITE_COUNTRY: z.enum(['SE', 'UK']),
    VITE_SENTRY_DSN: z.string().url(),
    VITE_SANITY_PROJECT_ID: z.string(),
    VITE_SANITY_DATASET: z.string(),
    VITE_SANITY_API_TOKEN: z.string().optional(),
    VITE_SANITY_API_VERSION: z.string()
  })
  .transform(env => ({
    env: env.VITE_ENV,
    apiRoot: env.VITE_API_ROOT,
    marketingSiteUrl: env.VITE_MARKETING_SITE_URL,
    segmentToken: env.VITE_SEGMENT_TOKEN,
    freshdeskKey: env.VITE_FRESHDESK_KEY,
    loqateKey: env.VITE_LOQATE_KEY,
    version: env.VITE_VERSION,
    country: env.VITE_COUNTRY,
    dvcSdkKey: env.VITE_DVC_SDK_KEY,
    sentryDsn: env.VITE_SENTRY_DSN,
    sanityProjectId: env.VITE_SANITY_PROJECT_ID,
    sanityDataset: env.VITE_SANITY_DATASET,
    sanityApiToken: env.VITE_SANITY_API_TOKEN,
    sanityApiVersion: env.VITE_SANITY_API_VERSION
  }));

export const environment = environmentSchema.parse(import.meta.env);
