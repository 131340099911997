import BankAccountUpdateInProgress from './BankAccountUpdateInProgress';
import CreditTierMigrationSuccess from './CreditTierMigrationSuccess';
import MigrationToNewTierNeeded from './MigrationToNewTierNeeded';
import OverduePayment from './OverduePayment';
import PaymentFreeMonthWithdrawalBlocked from './PaymentFreeMonthWithdrawalBlocked';
import PaymentProtectionInsurancePromo from './PaymentProtectionInsurancePromo';
import PaymentProtectionInsuranceSuccess from './PaymentProtectionInsuranceSuccess';
import PaymentProtectionInsuranceTerms from './PaymentProtectionInsuranceTerms';
import WithdrawalSuccess from './WithdrawalSuccess';

const SE = {
  BANK_ACCOUNT_UPDATE_IN_PROGRESS: BankAccountUpdateInProgress,
  CREDIT_TIER_MIGRATION_SUCCESS: CreditTierMigrationSuccess,
  MIGRATION_TO_NEW_TIER_NEEDED: MigrationToNewTierNeeded,
  OVERDUE_PAYMENT: OverduePayment,
  PAYMENT_FREE_MONTH_WITHDRAWAL_BLOCKED: PaymentFreeMonthWithdrawalBlocked,
  PAYMENT_PROTECTION_INSURANCE_PROMO: PaymentProtectionInsurancePromo,
  PAYMENT_PROTECTION_INSURANCE_SUCCESS: PaymentProtectionInsuranceSuccess,
  PAYMENT_PROTECTION_INSURANCE_TERMS: PaymentProtectionInsuranceTerms,
  WITHDRAWAL_SUCCESS: WithdrawalSuccess
} as const;

export default SE;
