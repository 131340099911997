import AgreementHasDebt from './AgreementHasDebt';
import BankAccountUpdateFail from './BankAccountUpdateFail';
import BankAccountUpdateSuccess from './BankAccountUpdateSuccess';
import ChangeMonthlyPaymentFailed from './ChangeMonthlyPaymentFailed';
import ChangeMonthlyPaymentSuccess from './ChangeMonthlyPaymentSuccess';
import ConfirmWithdrawal from './ConfirmWithdrawal';
import DebtConsolidationDirectDebitMandateNotFound from './DebtConsolidationDirectDebitMandateNotFound';
import DepositFail from './DepositFail';
import DepositSuccess from './DepositSuccess';
import DirectDebitChargeInProgress from './DirectDebitChargeInProgress';
import DirectDebitConsent from './DirectDebitConsent';
import DirectDebitMandateNotFound from './DirectDebitMandateNotFound';
import DirectPaymentDetails from './DirectPaymentDetails';
import DirectPaymentFailed from './DirectPaymentFailed';
import DirectPaymentGenericError from './DirectPaymentGenericError';
import DirectPaymentNothingToPay from './DirectPaymentNothingToPay';
import DirectPaymentPaid from './DirectPaymentPaid';
import DirectPaymentSuccess from './DirectPaymentSuccess';
import InsufficientFunds from './InsufficientFunds';
import LevlaOfferSuccess from './LevlaOfferSuccess';
import LoanAgreement from './LoanAgreement';
import NoConnectedBankAccountInfo from './NoConnectedBankAccountInfo';
import NoRemainingDebt from './NoRemainingDebt';
import OverdueChargeInProgress from './OverdueChargeInProgress';
import OverduePaymentSuccess from './OverduePaymentSuccess';
import PaybackPlan from './PaybackPlan';
import RemainingDebtInfo from './RemainingDebtInfo';
import SekkiCredit from './SekkiCredit';
import ServiceTerms from './ServiceTerms';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import WithdrawalBlocked from './WithdrawalBlocked';
import WithdrawalFail from './WithdrawalFail';
import WithdrawalInProgress from './WithdrawalInProgress';
import WithdrawalIpAddressCheck from './WithdrawalIpAddressCheck';
import WithdrawalNotAllowedPastCollectionFee from './WithdrawalNotAllowedPastCollectionFee';
import WithdrawalNotPossible from './WithdrawalNotPossible';

const Common = {
  AGREEMENT_HAS_DEBT: AgreementHasDebt,
  BANK_ACCOUNT_UPDATE_FAIL: BankAccountUpdateFail,
  BANK_ACCOUNT_UPDATE_SUCCESS: BankAccountUpdateSuccess,
  CHANGE_MONTHLY_PAYMENT_SUCCESS: ChangeMonthlyPaymentSuccess,
  CHANGE_MONTHLY_PAYMENT_FAILED: ChangeMonthlyPaymentFailed,
  CONFIRM_WITHDRAWAL: ConfirmWithdrawal,
  DEBT_CONSOLIDATION_DIRECT_DEBIT_MANDATE_NOT_FOUND: DebtConsolidationDirectDebitMandateNotFound,
  DEPOSIT_FAIL: DepositFail,
  DEPOSIT_SUCCESS: DepositSuccess,
  DIRECT_DEBIT_CHARGE_IN_PROGRESS: DirectDebitChargeInProgress,
  DIRECT_DEBIT_CONSENT: DirectDebitConsent,
  DIRECT_DEBIT_MANDATE_NOT_FOUND: DirectDebitMandateNotFound,
  DIRECT_PAYMENT_DETAILS: DirectPaymentDetails,
  DIRECT_PAYMENT_FAILED: DirectPaymentFailed,
  DIRECT_PAYMENT_GENERIC_ERROR: DirectPaymentGenericError,
  DIRECT_PAYMENT_NOTHING_TO_PAY: DirectPaymentNothingToPay,
  DIRECT_PAYMENT_PAID: DirectPaymentPaid,
  DIRECT_PAYMENT_SUCCESS: DirectPaymentSuccess,
  INSUFFICIENT_FUNDS: InsufficientFunds,
  LEVLA_OFFER_SUCCESS: LevlaOfferSuccess,
  LOAN_AGREEMENT: LoanAgreement,
  NO_CONNECTED_BANK_ACCOUNT_INFO: NoConnectedBankAccountInfo,
  NO_REMAINING_DEBT: NoRemainingDebt,
  OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS: OverdueChargeInProgress,
  OVERDUE_PAYMENT_SUCCESS: OverduePaymentSuccess,
  PAYBACK_PLAN: PaybackPlan,
  REMAINING_DEBT_INFO: RemainingDebtInfo,
  SEKKI_CREDIT: SekkiCredit,
  SERVICE_TERMS: ServiceTerms,
  TERMS_AND_CONDITIONS: TermsAndConditionsModal,
  WITHDRAWAL_BLOCKED: WithdrawalBlocked,
  WITHDRAWAL_FAIL: WithdrawalFail,
  WITHDRAWAL_IN_PROGRESS: WithdrawalInProgress,
  WITHDRAWAL_IP_ADDRESS_CHECK: WithdrawalIpAddressCheck,
  WITHDRAWAL_NOT_ALLOWED_PAST_COLLECTION_FEE: WithdrawalNotAllowedPastCollectionFee,
  WITHDRAWAL_NOT_POSSIBLE: WithdrawalNotPossible
} as const;

export default Common;
