import { Stack } from '@fairlo/ui-kit';
import styled from 'styled-components';

import TermsAndConditionsAccordion from '../../../UK/credit/TermsAndConditionsAccordion';
import Modal from '../../Modal';
import { modalAccordionStyles } from '../styled';
import { ModalParagraph } from '../shared';

const StyledTermsAndConditions = styled(TermsAndConditionsAccordion)`
  ${modalAccordionStyles};
`;

const TermsAndConditions = () => (
  <Modal title="Terms and Conditions">
    <Stack spacing={24}>
      <ModalParagraph>Last updated: 9 July 2024</ModalParagraph>

      <StyledTermsAndConditions />
    </Stack>
  </Modal>
);

export default TermsAndConditions;
