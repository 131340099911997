import * as Sentry from '@sentry/react';
import { z } from 'zod';
import { ErrorId } from '../constants';
import { environment } from './env.utils';

export const apiErrorSchema = z
  .object({
    response: z.object({
      status: z.number(),
      data: z.object({
        error: z.object({
          errorId: z.number(),
          code: z.string().optional(),
          message: z.string().optional(),
          metadata: z.record(z.string(), z.unknown()).optional()
        })
      })
    })
  })
  .transform(({ response: { status, data } }) => ({
    status,
    errorId: data.error.errorId as ErrorId,
    message: data.error.message,
    code: data.error.code,
    metadata: data.error.metadata
  }));

type ParsedApiError = z.infer<typeof apiErrorSchema>;

export const parseApiError = (error: unknown): ParsedApiError => {
  const result = apiErrorSchema.safeParse(error);

  if (!result.success) {
    Sentry.setContext('details', {
      apiError: error,
      zodError: result.error
    });

    throw new Error('Error parsing API error response', { cause: error });
  }

  return result.data;
};

type ReportExceptionOptions = {
  level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'; // Sentry.SeverityLevel
  extra?: Record<string, unknown>;
};

const logLevelMap = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  log: 'log',
  info: 'info',
  debug: 'log'
} as const satisfies Record<ReportExceptionOptions['level'], string>;

export const reportException = (exception: unknown, options?: ReportExceptionOptions) => {
  if (environment.env === 'production') {
    Sentry.captureException(exception, options);
  } else {
    const logLevel = logLevelMap[options?.level ?? 'error'];
    // eslint-disable-next-line no-console
    console[logLevel]('Exception to report:', exception);
  }
};
