import { Colors, Text } from '@fairlo/ui-kit';
import styled, { css } from 'styled-components';

import productTiersUKCredit from '../../../constants/UK/productTiersUKCredit';
import { formatCurrency } from '../../../localization/currency';

const cellStyles = css`
  width: calc(100% / 3);
  padding: 4px;
  margin: 0;
  text-align: center;
  border-left: var(--document-table-border);
  border-right: var(--document-table-border);

  // First column
  &:nth-child(3n + 1) {
    border-left: none;
  }

  // Third column
  &:nth-child(3n + 3) {
    border-right: none;
  }
`;

const Th = styled(Text)`
  ${cellStyles};
`;

const Td = styled(Text)`
  ${cellStyles};
`;

const Table = styled.table`
  --document-table-border: 1px solid ${Colors.ORANGE_200};
  border-collapse: collapse;

  tbody > tr {
    border-top: var(--document-table-border);
    border-bottom: var(--document-table-border);

    &:last-child {
      border-bottom: none;
    }
  }
`;

const InterestRatesTable = () => (
  <Table>
    <thead>
      <tr>
        <Th as="th" weight="medium">
          Credit Limit
        </Th>
        <Th as="th" weight="medium">
          Annual Interest Rate
        </Th>
        <Th as="th" weight="medium">
          APR
        </Th>
      </tr>
    </thead>
    <tbody>
      {Object.values(productTiersUKCredit).map(({ amount, apr }) => (
        <tr key={amount}>
          <Td as="td">{formatCurrency(amount, { currency: 'GBP' })}</Td>
          <Td as="td">{apr}%</Td>
          <Td as="td">{apr}%</Td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default InterestRatesTable;
