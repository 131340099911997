import axiosInstance from '../../utils/axiosInstance';
import { GetInsuranceResponse, PostInsuranceResponse } from './types';

export const getInsurance = async (token?: string) => {
  const apiURL = token ? `/se/insurance?token=${token}` : '/se/insurance';
  const response = await axiosInstance.get<GetInsuranceResponse>(apiURL);
  return response.data;
};

export const postInsurance = async (token?: string) => {
  const response = await axiosInstance.post<PostInsuranceResponse>('/se/insurance', {
    token
  });
  return response.data;
};
