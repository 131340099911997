import { device } from '@fairlo/ui-kit';
import { ReactNode } from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

import { Container } from '../styled';
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';

const Wrapper = styled.div`
  --layout-max-width: 1128px;
  --layout-horizontal-padding: 24px;
  --header-height: 80px;

  background-color: var(--layout-background-color, none);
  min-height: 100vh;
`;

const Main = styled(Container).attrs({ as: 'main' })`
  --main-padding-bottom: 64px;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  padding-bottom: var(--main-padding-bottom);
  // Isolate this element so its contents always appear below the header navigation menu
  isolation: isolate;

  @media (${device.tablet}) {
    --main-padding-bottom: 80px;
  }
`;

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  const viewportHeight = use100vh();

  return (
    <Wrapper
      style={{
        // @ts-expect-error The following is valid but TS still complains
        '--100dvh': viewportHeight ? `${viewportHeight}px` : '100vh'
      }}
    >
      <CookieBanner />

      <Header />

      <Main>{children}</Main>
    </Wrapper>
  );
};

export default Layout;
