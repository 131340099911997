import SE from './SE';
import UK from './UK';
import * as credit from './credit';
import * as debtConsolidation from './debtConsolidation/debtConsolidation';
import * as login from './login';
import * as marketingConsent from './marketingConsent';
import * as me from './me/me';
import * as mobileVerification from './mobileVerification';
import * as offers from './offers';
import * as paybackPlan from './paybackPlan';
import * as paymentProvider from './paymentProvider';
import * as promotions from './promotions';
import * as user from './user';
import * as users from './users';
import * as waitlist from './waitlist';

const API = {
  SE,
  UK,
  login,
  credit,
  paymentProvider,
  user,
  users,
  marketingConsent,
  me,
  mobileVerification,
  debtConsolidation,
  paybackPlan,
  offers,
  waitlist,
  promotions
};

export default API;
export * from './SE/applicants/types';
export * from './SE/bankId/types';
export * from './UK/applicants/types';
export * from './credit/types';
export * from './debtConsolidation/types';
export * from './marketingConsent/types';
export * from './me/types';
export * from './mobileVerification/types';
export * from './paybackPlan/types';
export * from './paymentProvider/types';
export * from './promotions/types';
export * from './waitlist/types';
