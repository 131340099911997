import { useCallback } from 'react';
import { trackEvent } from '../../analytics';
import { AnalyticsEvents } from '../../constants/analytics';
import { useModalStore } from '../../store/modalStore';

const useModal = () => {
  const { openModal: openModalFn, closeModal } = useModalStore();

  const openModal = useCallback(
    (...args: Parameters<typeof openModalFn>) => {
      const [type, options] = args;
      openModalFn(...args);

      void trackEvent(
        AnalyticsEvents.modalOpen(type),
        options?.payload
          ? {
              payload: options.payload
            }
          : undefined
      );
    },
    [openModalFn]
  );

  return { openModal, closeModal };
};

export default useModal;
