import { Button, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import useTerminateCreditMutation from '../../../pages/UserProfile/hooks/useTerminateCreditMutation';
import Textarea from '../../Textarea';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type FormValues = {
  reason: string;
};

function NoRemainingDebtInfo({ closeModal }: ModalProps) {
  const { t } = useTranslation(['Modal', 'Button', 'FormErrors']);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({ mode: 'all', defaultValues: { reason: '' } });
  const { mutate: terminateCredit, isPending } = useTerminateCreditMutation({
    onSettled: closeModal
  });
  const onSubmit = ({ reason }: FormValues) => terminateCredit({ reason });

  return (
    <Modal title={t('NoRemainingDebtInfo.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <ModalParagraph>{t('NoRemainingDebtInfo.info')}</ModalParagraph>
          <ModalParagraph>{t('NoRemainingDebtInfo.feedbackInfo')}</ModalParagraph>

          <Textarea
            {...register('reason', {
              required: t('FormErrors:required'),
              maxLength: { value: 300, message: t('FormErrors:maxLength', { length: 300 }) }
            })}
            aria-label={t('NoRemainingDebtInfo.feedbackLabel')}
            disabled={isPending}
            errorMessage={errors.reason?.message}
            rows={3}
          />
        </Stack>

        <Modal.Actions>
          <Button type="submit" color="alert" loading={isPending} disabled={!isValid}>
            {t('NoRemainingDebtInfo.action')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
}

export default NoRemainingDebtInfo;
