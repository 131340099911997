import { useCookie } from 'react-use';
import { cookies } from '../../../../constants/cookies';

//Note: If we want to use this hook in another component, we need to make use of global store
const useCookieConsent = () => {
  const { name, expires } = cookies.cookieConsent;
  const [cookieConsentValue, updateCookieConsentValue] = useCookie(name);

  const hasConsent = cookieConsentValue === 'true';

  const giveConsent = () => {
    updateCookieConsentValue('true', { expires });
  };

  return {
    hasConsent,
    giveConsent
  };
};

export default useCookieConsent;
