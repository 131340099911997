import { Stack, Text, formatCurrency } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { ModalParagraph, TermsHeadingText } from './shared';
import { ModalProps } from './types';

const today = new Date().toLocaleDateString('sv-SE', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
});

type Payload = {
  userData: {
    email: string;
    nationalNumber: string;
  };
};
type Props = ModalProps<Payload>;

function LoanAgreement({ payload: { userData } }: Props) {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('LoanAgreement.title')}>
      <Stack>
        <ModalParagraph>Förmedlad av Fairlo AB (Fairlo)</ModalParagraph>

        <ModalParagraph>{today}</ModalParagraph>
        <ModalParagraph>Avtalsnummer: 123456789</ModalParagraph>

        <TermsHeadingText as="h2">1. Allmän information</TermsHeadingText>

        <ModalParagraph>
          Kreditgivare är Nordiska Kreditmarknadsaktiebolaget (publ), organisationsnummer 556760-6032, Box
          173, 101 23 Stockholm.
        </ModalParagraph>

        <ModalParagraph>
          Kreditförmedlare är Fairlo AB, organisationsnummer 559084-1101, Box: 110 10, 100 61 Stockholm.
        </ModalParagraph>

        <ModalParagraph>
          Kreditgivaren har givit Fairlo AB, i uppdrag att administrera krediten för Kreditgivarens räkning,
          varför Kredittagaren fortsatt kan vända sig till Fairlo AB med eventuella frågor till dess
          Kreditgivaren meddelar annorlunda.
        </ModalParagraph>

        <ModalParagraph>
          Detta är Kredittagarens kreditavtal för Fairlo Varda. Avtalet har idag ({today}) ingåtts mellan
          Kredittagaren och Kreditgivaren. Nedan framgår de specifika egenskaperna och villkoren för
          Kredittagarens kredit. För övriga villkor och bestämmelser gäller Fairlos Allmänna villkor samt den
          Betalningsplan som vid varje enskild tidpunkt gäller för krediten och som framgår av Kredittagarens
          Mina sidor på www.fairlo.se. Vid signering av detta Kreditavtal godkänner Kredittagaren i) att
          Fairlos Allmänna villkor är en del av Kreditavtalet och att Kredittagaren tagit del av och godkänt
          dessa och ii) att den Betalningsplan som vid varje enskild tidpunkt godkänts av Kredittagaren med
          BankID via www.fairlo.se är en del av Kreditavtalet.
        </ModalParagraph>

        <TermsHeadingText as="h2">2. Parter</TermsHeadingText>

        <Stack spacing={0}>
          <TermsHeadingText as="h3">Kredittagare</TermsHeadingText>
          <Text as="span">{userData.email}</Text>
          <Text as="span">
            {/* Add dash before the 4th digit from the last */}
            Personnummer: {userData.nationalNumber.replace(/(\d{4})$/, '-$1')}
          </Text>
        </Stack>

        <Stack spacing={0}>
          <TermsHeadingText as="h3">Kreditgivare</TermsHeadingText>
          <Text as="span">Nordiska Kreditmarknadsaktiebolaget (publ)</Text>
          <Text as="span">Box 173</Text>
          <Text as="span">101 23 STOCKHOLM</Text>
        </Stack>

        <Text as="span">Organisationsnummer: 556760-6032</Text>

        <TermsHeadingText as="h2">3. Krediten</TermsHeadingText>

        <div>
          <Text as="span" weight="bold">
            Typ av kredit
          </Text>
          <ModalParagraph>
            Kontokredit utan säkerhet. Kredittagaren ges rätt att nyttja en kredit inom ramen för beviljat
            Kreditutrymme.
          </ModalParagraph>
          <ModalParagraph>Vad som avses med Kreditutrymme framgår av de Allmänna villkoren.</ModalParagraph>
        </div>

        <div>
          <Text as="span" weight="bold">
            Nyttjande
          </Text>
          <ModalParagraph>
            Kredit utbetalas till det bankkonto som Kredittagaren har angivit och anslutit.
          </ModalParagraph>
        </div>

        <div>
          <Text as="span" weight="bold">
            Återbetalning
          </Text>
          <ModalParagraph>Månadsbelopp i enlighet med en annuitetsplan.</ModalParagraph>
        </div>

        <div>
          <Text as="span" weight="bold">
            Månadsbelopp
          </Text>
          <ModalParagraph>
            Vilket månadsbelopp Kredittagaren ska betala framgår av den senast avtalade Betalningsplanen i
            enlighet med Fairlos Allmänna villkor.
          </ModalParagraph>
        </div>

        <div>
          <Text as="span" weight="bold">
            Kreditutrymme
          </Text>
          <ModalParagraph>
            Upp till {formatCurrency(100_000, { currency: 'SEK', displaySign: false })} kronor
          </ModalParagraph>
        </div>
        <div>
          <Text as="span" weight="bold">
            Ränta
          </Text>
          <ModalParagraph>Enligt Prisbilagan i Fairlos Allmänna villkor.</ModalParagraph>
        </div>

        <div>
          <Text as="span" weight="bold">
            Löptid
          </Text>
          <ModalParagraph>Tills vidare</ModalParagraph>
        </div>
        <div>
          <Text as="span" weight="bold">
            Total Kostnad
          </Text>
          <ModalParagraph>
            Den totala kostnaden framgår av den senast avtalade Betalningsplanen. Kredittagarens totala
            kostnad för krediten beror dels på den omfattning Kredittagaren valt att utnyttja Krediten och
            dels på Kredittagarens återbetalningstakt. Ett exempel på kostnadsfördelning finns i
            konsumentkreditinformationen (“SEKKI”).
          </ModalParagraph>
        </div>

        <div>
          <TermsHeadingText as="h2">4. Signering</TermsHeadingText>
          <ModalParagraph>
            Detta avtal har upprättats elektroniskt och signerats med personligt BankID av Kredittagaren.
            Kredittagaren har mottagit en kopia av detta kreditavtal och de Allmänna villkoren via e-post till
            den e-postadress som Kredittagaren angivit i sin ansökan om kredit.
          </ModalParagraph>
        </div>
      </Stack>
    </Modal>
  );
}

export default LoanAgreement;
