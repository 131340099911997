type ProductTier = '1k' | '2k' | '3k' | '5k' | '7k' | '10k';

export type ProductTierData = {
  amount: number;
  apr: number;
  maxDuration: number;
  minMonthlyAmount: number;
  cost: number;
};

/**
 * This object is only to be used on static texts and legal documents. It should never be used in business logic.
 */
const productTiersUKCredit: Record<ProductTier, ProductTierData> = {
  '1k': {
    amount: 1_000,
    apr: 29,
    maxDuration: 36,
    minMonthlyAmount: 42,
    cost: 509
  },
  '2k': {
    amount: 2_000,
    apr: 29,
    maxDuration: 48,
    minMonthlyAmount: 71,
    cost: 1_401
  },
  '3k': {
    amount: 3_000,
    apr: 26,
    maxDuration: 60,
    minMonthlyAmount: 90,
    cost: 2_390
  },
  '5k': {
    amount: 5_000,
    apr: 25,
    maxDuration: 60,
    minMonthlyAmount: 147,
    cost: 3_806
  },
  '7k': {
    amount: 7_000,
    apr: 23,
    maxDuration: 60,
    minMonthlyAmount: 198,
    cost: 4_840
  },
  '10k': {
    amount: 10_000,
    apr: 20,
    maxDuration: 60,
    minMonthlyAmount: 265,
    cost: 5_897
  }
};

export default productTiersUKCredit;
