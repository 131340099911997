import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usePPIPromoModalCooldownPeriod from '../../../../pages/Products/SE/views/Credit/hooks/usePPIPromoModalCooldownPeriod';
import useWithdrawNavigation from '../../../../pages/Products/SE/views/Credit/hooks/useWithdrawNavigation';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const PaymentProtectionInsurancePromo = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);
  const { setCooldownPeriod } = usePPIPromoModalCooldownPeriod();
  const { navigateToWithdraw, isWithdrawNavigationLoading } = useWithdrawNavigation();

  useEffect(() => {
    setCooldownPeriod();
  }, [setCooldownPeriod]);

  const handleClick = () => {
    closeModal();
    navigateToWithdraw();
  };

  return (
    <Modal title={t('PaymentProtectionInsurancePromo.title')}>
      <ModalParagraph>{t('PaymentProtectionInsurancePromo.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton as={Link} to={routeMap.paymentProtectionInsurance.root}>
          {t('PaymentProtectionInsurancePromo.insureButton')}
        </AnchorButton>
        <Button variant="outlined" disabled={isWithdrawNavigationLoading} onClick={handleClick}>
          {t('PaymentProtectionInsurancePromo.notInsureButton')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentProtectionInsurancePromo;
