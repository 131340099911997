import { Colors, formatCurrency, Stack, Text } from '@fairlo/ui-kit';
import { Translation, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QaLocales } from '../../../types/questionsAndAnswers';
import Modal from '../Modal';
import { ModalParagraph, TermsHeadingText } from './shared';

const ModalTableList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const Table = styled.table`
  border-collapse: collapse;
  text-align: center;
  width: 100%;

  th,
  td {
    border: 1px solid ${Colors.GRAY_300};
    padding: 5px;
  }
`;

/* prettier-ignore */
const tableValues = [
  ['0 - 29 999',      29, 0 ],
  ['30 000 - 39 999', 29, 9 ],
  ['40 000 - 49 999', 28, 19],
  ['50 000 - 59 999', 26, 19],
  ['60 000 - 69 999', 24, 19],
  ['70 000',          20, 29]
];

const termsAndConditions: QaLocales = {
  'sv-SE': {
    name: 'Allmänna villkor',
    readMore: false,
    questions: [
      {
        question: 'Allmän information',
        answer: (
          <Stack>
            <ModalParagraph>
              Dessa allmänna villkor (”Allmänna villkor”) är en del av det kreditavtal (”Kreditavtal”) som
              Kredittagaren har ingått med Kreditgivaren.
            </ModalParagraph>
            <ModalParagraph>
              Dessa allmänna villkor gäller för Fairlos tjänster Fairlo Varda och Fairlo Lösa. Bestämmelserna
              i Del 2 gäller endast för tjänsten Fairlo Lösa. Bestämmelserna i Del 3 gäller endast för
              tjänsten Fairlo Varda. Bestämmelserna i Del 4 gäller för både Fairlo Lösa och Fairlo Varda.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivare är Nordiska Kreditmarknadsaktiebolaget (publ) med organisationsnummer 556760-6032
              och postadress Box 173, 101 23 Stockholm (“Kreditgivaren”) som tillhandahåller kontokrediter
              utan säkerhet till privatpersoner.
            </ModalParagraph>
            <ModalParagraph>
              Kreditförmedlare är Fairlo AB med organisationsnummer 559084-1101 och postadress Box: 110 10,
              100 61 Stockholm (“Kreditförmedlaren”). Kreditgivaren har givit Fairlo i uppdrag att förmedla
              och administrera kredithanteringen och avtalsrelationen för Kreditgivarens räkning.
            </ModalParagraph>
            <ModalParagraph>
              Villkoren för Krediten framgår av dessa Allmänna villkor och Kreditavtalet. Genom att ingå
              Kreditavtalet förbinder sig Kredittagaren att följa Kreditavtalet, dessa Allmänna villkor samt
              den Betalningsplan som vid varje given tidpunkt gäller för Krediten.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Definitioner',
        answer: (
          <Stack>
            <ModalParagraph>
              Med “Kreditutrymme” avses det totala kreditbelopp Kredittagaren som maximalt kan utnyttja.
            </ModalParagraph>
            <ModalParagraph>
              Med “Betalningsplan” avses den aktuella annuitetsplanen för avbetalning som Kredittagaren valt.
            </ModalParagraph>
            <ModalParagraph>
              Med “Månadsbelopp” avses det månatliga belopp som Kredittagaren ska erlägga i enlighet med
              Betalningsplanen. Månadsbeloppet utgörs av ränta och amortering för utestående skuld.
            </ModalParagraph>
            <ModalParagraph>
              Med “Extraamortering” avses amortering som Kredittagaren gör utöver vad som följer av
              Månadsbeloppet och Betalningsplanen.
            </ModalParagraph>
            <ModalParagraph>
              Med “Uttag” avses utbetalning av Kredit till Kreditgivarens anslutna bankkonto för tjänsten
              Fairlo Varda och refinansiering av kredit hos Tredje Part för tjänsten Fairlo Lösa.
            </ModalParagraph>
            <ModalParagraph>
              Med “Kreditgivare” avses Nordiska Kreditmarknadsaktiebolaget (publ), organisationsnummer
              556760-6032, Box 173, 101 23 Stockholm.
            </ModalParagraph>
            <ModalParagraph>Med ”Allmänna villkor” avses detta dokument.</ModalParagraph>
            <ModalParagraph>
              Med ”Kredittagare” avses den som ansökt om Kredit eller har ingått ett Kreditavtal med
              Kreditgivaren.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Kredit” avses det lånebelopp Kredittagaren ansöker om på Fairlos webbplats www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Kreditförmedlare” avses Fairlo AB, organisationsnummer 559084-1101, Box: 110 10, 100 61
              Stockholm.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Politiskt utsatt ställning” avses en person som har eller har haft viktiga offentliga
              funktioner i en stat eller i en internationell organisation eller familjemedlem eller känd
              medarbetare till sådan person.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Obegränsat skattskyldig” avses den innebörd som framgår av inkomstskattelagen innefattande
              domstolars och andra myndigheters tolkning av begreppet.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Kreditprövning” avses den prövning som Fairlo gör för att bedöma Kredittagarens möjlighet
              att återbetala Krediten jämte ränta och i förekommande fall avgifter samt lämpligheten i att
              Kredittagaren skuldsätter sig.
            </ModalParagraph>
            <ModalParagraph>
              Med ”Tredje Part” avses bank, finansinstitut eller annan juridisk person där Kredittagaren har
              en eller flera krediter och som Fairlo refinansierar enligt Kreditavtalet och dessa Allmänna
              villkor.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Om Fairlos tjänster',
        answer: (
          <Stack>
            <ModalParagraph>
              Fairlo förmedlar kredit genom sina tjänster Fairlo Varda och Fairlo Lösa.
            </ModalParagraph>
            <ModalParagraph>
              Fairlo Varda är en kontokredit som ger Kredittagaren rätt att fortlöpande utnyttja ett
              Kreditutrymme under avtalstiden. Vilket Kreditutrymme Kredittagaren beviljas beror på vilket
              Kreditutrymme Fairlo beviljar Kredittagaren.
            </ModalParagraph>
            <ModalParagraph>
              Fairlo Lösa är en tjänst som möjliggör refinansiering av en eller flera existerande krediter som
              Kredittagaren har hos Tredje Part. Refinansieringen innebär att Kreditgivaren helt eller delvis
              löser Kredittagarens befintliga krediter hos Tredje Part och att Kredittagaren genom
              refinansieringen får en samlad Kredit hos Fairlo.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren kan ansöka om Kredit på Fairlos webbsida www.fairlo.se. Kredittagaren identifierar
              sig och ansöker om Kredit genom personlig e-legitimation (BankID). Vilket Kreditutrymme
              Kredittagaren beviljats framgår på Mina sidor på www.fairlo.se.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Särskilda villkor för Fairlo Lösa',
        answer: (
          <Stack>
            <ModalParagraph>
              Innehållet i Del 2 i dessa Allmänna villkor gäller endast för Fairlos tjänst Fairlo Lösa. I Del
              4 kan du läsa vilka ytterligare villkor som gäller för tjänsten Fairlo Lösa.
            </ModalParagraph>
            <ModalParagraph>
              Med hjälp av tjänsten Fairlo Lösa kan Kredittagaren refinansiera en eller flera av sina krediter
              hos Tredje Part. Det sker genom att Fairlo löser krediterna hos Tredje Part och att
              Kredittagaren i stället får en samlad kredit hos Fairlo.
            </ModalParagraph>
            <ModalParagraph>
              För att kunna ansöka om Kredit måste Kredittagaren först acceptera Fairlos tjänstevillkor för
              inhämtning av Kredittagarens kredit- och kontoinformation hos Tredje Part. Innan Kredittagaren
              ansöker om kredit kommer Fairlo att informera Kredittagaren om de villkor som kommer att gälla
              om Kredittagaren väljer att refinansiera en eller flera av sina krediter.
            </ModalParagraph>
            <ModalParagraph>
              Vid beviljad ansökan löser Kreditgivaren en eller flera av Kredittagarens krediter hos Tredje
              Part. Om Kredittagarens ansökan beviljas kommer den eller de Krediter som Kredittagaren angivit
              att refinansieras enligt Kreditavtalet och dessa Allmänna villkor.
            </ModalParagraph>
            <ModalParagraph>
              En förutsättning för att refinansiering av en kredit ska kunna ske är att Kredittagaren genomgår
              en kreditprövning och i övrigt uppfyller de krav som ställs för beviljande av kredit.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Särskilda villkor för Fairlo Varda',
        answer: (
          <Stack>
            <ModalParagraph>
              Innehållet i Del 3 i dessa Allmänna villkor gäller endast för Fairlos tjänst Fairlo Varda. I Del
              4 kan du läsa vilka ytterligare villkor som gäller för tjänsten Fairlo Varda.
            </ModalParagraph>
            <ModalParagraph>
              Genom tjänsten Fairlo Varda kan Kredittagaren ansöka om kredit. Vid ansökan om kredit beviljas
              Kredittagaren ett bestämt kreditutrymme. Kredittagaren kan nyttja Kreditutrymmet på det sätt som
              Kredittagaren önskar. Det innebär att Kredittagaren när som helst kan överföra Krediten till
              sitt bankkonto, under förutsättning att Kreditutrymmet inte överskrids. Krediten kan endast
              överföras till ett bankkonto i Kredittagarens eget namn och som är anslutet till Kredittagarens
              personliga e-legitimation (BankID).
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren utnyttjar hela Kreditutrymmet, men önskar göra ytterligare uttag, måste
              Kredittagaren antingen ansöka om ett högre Kreditutrymme eller betala hela eller delar av
              skulden.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Ansökan om Kredit',
        answer: (
          <Stack>
            <ModalParagraph>En ansökan om Kredit kan endast beviljas om Kredittagaren</ModalParagraph>
            <ul>
              <li>
                <Text as="span">(i) är en fysisk person över 20 år,</Text>
              </li>
              <li>
                <Text as="span">(ii) har ett svenskt personnummer,</Text>
              </li>
              <li>
                <Text as="span">(iii) är folkbokförd i Sverige,</Text>
              </li>
              <li>
                <Text as="span">(iv) har ett bankkonto i en svensk bank,</Text>
              </li>
              <li>
                <Text as="span">(v) har ett personligt BankID samt</Text>
              </li>
              <li>
                <ModalParagraph>
                  (vi) genomgår och uppfyller de krav som ställs enligt Kreditgivarens eller Fairlos
                  Kreditprövning.
                </ModalParagraph>
              </li>
            </ul>
            <ModalParagraph>
              Kreditgivaren eller Fairlo förbehåller sig rätten att ställa ytterligare villkor för beviljande
              av Kredit.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Ingående av Kreditavtal',
        answer: (
          <Stack>
            <ModalParagraph>
              <em>Kreditprövning</em>
            </ModalParagraph>
            <ModalParagraph>
              För att beviljas Kredit måste Kredittagaren genomgå en Kreditprövning. Kreditgivaren gör med
              utgångspunkt i Kreditprövningen en samlad bedömning av Kredittagarens kreditvärdighet.
              Kreditgivaren beaktar bland annat lämpligheten i att Kredittagaren skuldsätter sig på det sätt
              som Kredittagaren angivit i sin ansökan och Kredittagarens möjligheter att återbetala Krediten.
            </ModalParagraph>
            <Text as="span">
              <em>Utkast på Kreditavtal</em>
            </Text>
            <ModalParagraph>
              Fairlo tillhandahåller Kredittagaren ett utkast på Kreditavtalet innan kreditprövning sker. Av
              utkastet kan Kredittagaren se de villkor som kommer att gälla mellan Kredittagaren och
              Kreditgivaren om Kredittagaren väljer att ingå Kreditavtalet. Av utkastet framgår bland annat
              Kreditavtalets löptid, information om nominell och effektiv ränta, Kreditutrymmet samt
              eventuella övriga tilläggstjänster som Kredittagaren ansökt om. Syftet med att tillhandahålla
              Kredittagaren ett utkast på Kreditavtalet är att Kredittagaren på ett enkelt och tydligt sätt
              ska förstå hur mycket Krediten kommer att kosta och vilka villkor som kommer att gälla om ett
              Kreditavtal ingås.
            </ModalParagraph>
            <ModalParagraph>
              Utkastet till Kreditavtal är upprättat med utgångspunkt i det Kreditutrymme som Kredittagaren
              beviljats, det maximala uttag som går att göra baserat på Kreditutrymmet samt den av
              Kredittagaren valda Betalningsplanen. Kredittagaren kan ändra sina val gällande Kreditavtalets
              löptid och önskat Kreditutrymme och få ett förnyat utkast till Kreditavtal innan Kreditavtal
              ingås.
            </ModalParagraph>
            <ModalParagraph>
              <em>Ingående av Kreditavtal</em>
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren godkänner utkastet till Kreditavtal genom att signera det med sin personliga
              e-legitimation (BankID) ingår Kredittagaren ett Kreditavtal med Kreditgivaren. Efter att ha
              godkänt utkastet är Kredittagaren bunden av det innehåll som framgår av Kreditavtalet och dessa
              Allmänna villkor. Kredittagaren har rätt att ångra sitt avtal enligt punkt 18 i dessa Allmänna
              villkor.
            </ModalParagraph>
            <ModalParagraph>
              <em>Kredittagarens försäkran</em>
            </ModalParagraph>
            <ModalParagraph>Genom att ingå ett Kreditavtal försäkrar Kredittagaren att:</ModalParagraph>
            <ul>
              <li>
                <ModalParagraph>
                  Kredittagaren senast i samband med ansökan om Kredit, eller annars när omständigheterna
                  förändras under Avtalstiden, meddelar Kreditgivaren om denne är (eller har varit) (i) person
                  i Politiskt utsatt ställning, (ii) familjemedlem till sådan person som omnämns i punkt (i)
                  eller (iii) känd medarbetare till en sådan person som omnämns i punkt (i) eller (ii).
                </ModalParagraph>
              </li>
              <li>
                <Text as="span">Kredittagaren är Obegränsat skattskyldig i Sverige,</Text>
              </li>
              <li>
                <Text as="span">Kredittagaren inte ansöker om Kredit för någon annans räkning.</Text>
              </li>
            </ul>
            <ModalParagraph>
              <em>Kreditgivarens ensidiga bestämmanderätt</em>
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren beslutar ensidigt om Kredittagarens ansökan om Kredit ska beviljas. Om
              Kredittagarens ansökan helt eller delvis inte beviljas har Kredittagaren rätt att efter framförd
              skriftlig begäran få information om orsaken till Kreditgivarens beslut.
            </ModalParagraph>
            <ModalParagraph>
              <em>Efter ingående av Kreditavtal</em>
            </ModalParagraph>
            <ModalParagraph>
              När Kreditavtalet har ingåtts skickar Fairlo Kreditavtalet, de Allmänna villkoren samt SEKKI
              till den e-postadress som Kredittagaren har angivit i sin ansökan om Kredit. Kredittagaren ges
              samtidigt möjlighet att förfoga över Krediten. Kreditgivaren förbehåller sig dock rätten att
              begränsa Kredittagarens möjligheter till Uttag under den tid som avtalet är gällande, om så är
              motiverat med hänsyn till god kreditgivningssed, vid var tid gällande lagstiftning mot
              penningtvätt och finansiering av terrorism, kreditpolitiska beslut, verksamhetsrelaterad
              förändring hos Kreditgivaren eller Kreditförmedlaren eller annat liknande förhållande.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Hantering och skydd av personlig behörighetsfunktion',
        answer: (
          <Stack>
            <ModalParagraph>Skydd av personlig behörighetsfunktion</ModalParagraph>
            <ModalParagraph>Kredittagaren är skyldig att:</ModalParagraph>
            <ul>
              <li>
                <ModalParagraph>
                  inte överföra eller på annat sätt överlåta, eller låta annan använda, Kredittagarens
                  personliga behörighetsfunktion, såsom personlig e-legitimation (BankID).
                </ModalParagraph>
              </li>
              <li>
                <Text as="span">
                  vidta alla rimliga försiktighetsåtgärder för att skydda sin personliga behörighetsfunktion.
                </Text>
              </li>
              <li>
                <ModalParagraph>
                  Säkerställa att den personliga behörighetsfunktionen inte har något samband med
                  Kredittagarens person-, kort-, telefonnummer eller liknande, när Kredittagaren har möjlighet
                  att välja kod för sin personliga behörighetsfunktion.
                </ModalParagraph>
              </li>
              <li>
                <Text as="span">
                  säkerställa att obehöriga inte kan få tillgång till den personliga behörighetsfunktionen.
                </Text>
              </li>
              <li>
                <ModalParagraph>
                  använda sin personliga behörighetsfunktion under förhållanden så att obehöriga inte kan se
                  den.
                </ModalParagraph>
              </li>
              <li>
                <Text as="span">inte avslöja sin personliga behörighetsfunktion för någon annan.</Text>
              </li>
              <li>
                <ModalParagraph>
                  inte anteckna sina inloggningsuppgifter till den personliga behörighetsfunktionen så att
                  obehörig kan ges anledning att anta att anteckningen avser en personlig kod.
                </ModalParagraph>
              </li>
              <li>
                <ModalParagraph>
                  omedelbart ändra sina inloggningsuppgifter till den personliga behörighetsfunktionen vid
                  misstanke om att obehörig person fått eller kan ha fått kännedom om inloggningsuppgifterna.
                </ModalParagraph>
              </li>
              <li>
                <ModalParagraph>
                  skydda samt hålla god kontroll och uppsikt över sin mobila enhet, om den personliga
                  behörighetsfunktionen finns lagrad på en mobil enhet.
                </ModalParagraph>
              </li>
            </ul>
            <ModalParagraph>
              <em>Spärranmälan</em>
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren ska omedelbart meddela Fairlo vid misstanke eller kännedom om att obehörig person
              kan ha fått kännedom om Kredittagarens inloggningsuppgifter eller åtkomst till Kredittagarens
              personliga behörighetsfunktion. Samma gäller vid förlust av mobil enhet där Kredittagarens
              personliga behörighetsfunktion finns lagrad. När Kredittagaren meddelar Fairlo om detta kommer
              Kredittagarens möjligheter att förfoga över Krediten att begränsas.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Kredittagarens återbetalning av Krediten m.m.',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagaren ska återbetala Krediten, ränta och eventuella avgifter enligt Betalningsplanen.
              Kredittagaren kan när som helst se hur mycket Kredittagaren ska betala enligt Betalningsplanen
              genom att logga in på Mina sidor på www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren kan förändra Månadsbeloppet genom att göra Uttag från Kreditutrymmet, göra en
              Extraamortering eller ändra återbetalningstiden. Innan Kredittagaren gör ett Uttag eller en
              Extraamortering får Kredittagaren besked om hur den totala skulden kommer att förändras.
              Kredittagaren får samtidigt en ny Betalningsplan och information om ett nytt Månadsbelopp. När
              Kredittagaren bekräftar och genomför Uttaget eller Extraamorteringen blir det nya Månadsbeloppet
              det senast avtalade beloppet som Kredittagaren ska betala. Den nya Betalningsplanen ersätter den
              tidigare avtalade betalningsplanen.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren har också möjlighet att, ändra Månadsbeloppet genom att logga in på Mina sidor på
              www.fairlo.se och där välja att justera återbetalningstiden, om inte Kredittagaren är försenad
              med att betala delar av ett eller flera Månadsbelopp. Hur lång återbetalningstid Kredittagaren
              kan välja beror på hur stor del av Kreditutrymmet som Kredittagaren har utnyttjat. Innan
              återbetalningstiden förändras får Kredittagaren ett utkast på hur den totala skulden kommer att
              påverkas samt en ny Betalningsplan med ett nytt Månadsbelopp. När Kredittagaren har bekräftat
              och genomfört ändringen av återbetalningstiden blir det nya Månadsbeloppet det belopp som
              Kredittagaren ska betala. Den nya Betalningsplanen ersätter den föregående Betalningsplanen och
              Månadsbeloppet däri. Den nya Betalningsplanen gäller från och med den tidpunkt som Kredittagaren
              bekräftar och godkänner ändringen.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren har rätt att när som helst betala tillbaka hela eller delar av krediten till
              Kreditgivaren utan kostnad utöver upplupen ränta för den period som Kredittagaren utnyttjat
              Krediten. Detta gör Kredittagaren genom att logga in på Mina sidor på www.fairlo.se och följa
              instruktionerna.
            </ModalParagraph>
            <ModalParagraph>
              När Kredittagaren gör transaktioner som medför förändringar av storleken på Krediten och
              Månadsbeloppet får Kredittagaren en bekräftelse på transaktionen till sin registrerade
              e-postadress.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Avräkning',
        answer: (
          <ModalParagraph>
            Vid betalning av Krediten sker avräkning först mot förfallna avgifter, kostnader och räntor,
            därefter utestående kapitalskuld.
          </ModalParagraph>
        )
      },
      {
        question: 'Ränta',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagaren är skyldig att betala ränta på utnyttjat Kreditutrymme i enlighet med
              Kreditavtalet, liksom dröjsmålsränta i händelse av försenad betalning.
            </ModalParagraph>
            <ModalParagraph>
              Räntan är rörlig, vilket innebär att Kreditgivaren har rätt att omedelbart ändra räntesatsen i
              den utsträckning det motiveras av kreditpolitiska beslut, ökade upplåningskostnader för
              Kreditgivaren eller andra kostnadsökningar som Kreditgivaren inte skäligen hade kunnat förutse
              när Kreditavtalet ingicks. Kreditgivaren förbehåller sig även rätten att i annat fall ändra
              räntesatsen i den mån det är förenligt med vid var tid gällande konsumentkreditlagstiftning.
              Kredittagaren kommer att meddelas om planerade räntehöjningar och hur Kredittagarens
              Månadsbelopp kommer att förändras innan förändringarna börjar gälla.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Betalningsplan och kontoutdrag',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagaren kan alltid se sin aktuella Betalningsplan på Mina sidor på www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Betalningsplanen är en sammanställning över när kapitalbelopp och ränta ska betalas och är
              upprättad efter utgående skuldsaldo och tillämplig ränta och i förekommande fall tillämpliga,
              avgifter och kostnader.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren kan också se sitt kontoutdrag med uppgift om aktuellt skuldsaldo, Uttag och
              tidpunkt för Uttag, betalningar och tidpunkt för betalningar, upplupna räntor och avgifter, samt
              det Månadsbelopp Kredittagaren är skyldig att betala på Mina sidor.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Betalning och autogiro',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagarens betalning av Krediten, ränta och i förekommande fall kostnader eller avgifter
              sker genom autogiro den 25:e i varje månad. Om det saknas täckning kommer ytterligare dragningar
              att ske påföljande dagar och, om dragning fortfarande inte har kunnat ske, även vid nästföljande
              månads dragning. Om förfallodagen inte infaller på en bankdag kommer autogirodragningen äga rum
              bankdagen innan förfallodagen. Det bankkonto som Kredittagaren har anslutit debiteras med
              Månadsbeloppet på förfallodagen i enlighet med Betalningsplanen.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren måste ge sitt samtycke till att betalning sker genom autogiro. Det gör
              Kredittagaren genom att godkänna det särskilda autogiromedgivande som är en del av
              Kredittagarens ansökan om kredit. Om Kredittagaren inte ger sitt samtycke kommer Kredittagaren
              inte att kunna ingå ett kreditavtal med Kreditgivaren.
            </ModalParagraph>
            <ModalParagraph>
              En förutsättning för att betalning ska kunna ske genom autogiro är att Kredittagarens bank
              godkänner att Kredittagarens anslutna bankkonto kan användas för betalning genom autogiro samt
              att Kreditgivaren eller den part som Kreditgivaren anvisar godkänner Kredittagaren som användare
              av tillämplig autogirotjänst.
            </ModalParagraph>
            <ModalParagraph>
              När Kredittagaren ingår Kreditavtalet ger Kredittagaren Kreditgivaren fullmakt att hos
              kontoförande bank kontrollera att Kredittagaren är innehavare till bankkonto som Kreditgivaren
              har uppgivit i sin ansökan om Kredit.
            </ModalParagraph>
            <ModalParagraph>
              Om autogiro inte ansluts enligt ovan har Kreditgivaren rätt att stoppa utbetalning av Kredit
              samt säga upp Kreditavtalet.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren återkallar sitt autogiromedgivande äger Kreditgivaren rätt att ta ut en avgift
              på 249 kronor per månad fram tills Krediten till fullo har betalats.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Försenad betalning',
        answer: (
          <Stack>
            <ModalParagraph>
              Vid försenad, bristande eller utebliven betalning har Kreditgivaren rätt till dröjsmålsränta på
              utestående belopp fram till dess att Kreditgivaren erhåller full betalning. Kreditgivaren har
              också rätt att påföra en påminnelseavgift med det belopp som anges i den prisbilaga som finns i
              slutet av dessa Allmänna villkor. Kreditgivaren har också rätt att påföra vid var tid gällande
              lagstadgade inkassokostnader.
            </ModalParagraph>
            <ModalParagraph>
              Om Kreditgivaren överlämnar sin fordran på Kredittagaren för indrivning till
              Kronofogdemyndigheten tillkommer vid var tid gällande lagstadgade kostnader och avgifter. Vid
              försenad betalning har Kreditgivaren också rätt att säga upp Kreditavtalet enligt punkt 17 i
              dessa Allmänna villkor.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Kreditavtalets löptid',
        answer: (
          <ModalParagraph>
            Kreditavtalet gäller fram till dess att Kreditgivaren eller Kredittagaren avslutar Kreditavtalet i
            enlighet med 16, 17 eller 18 i dessa Allmänna villkor.
          </ModalParagraph>
        )
      },
      {
        question: 'Kredittagarens rätt att säga upp Kreditavtalet',
        answer: (
          <ModalParagraph>
            Kredittagaren har rätt att när som helst, oavsett hur länge denne haft Kreditutrymmet till sitt
            förfogande, avsluta Kreditavtalet. Det gör Kredittagaren genom att logga in på Mina sidor på
            www.fairlo.se och följa instruktionerna.
          </ModalParagraph>
        )
      },
      {
        question: 'Kreditgivarens rätt att säga upp Kreditavtalet',
        answer: (
          <Stack>
            <ModalParagraph>
              Kreditgivaren äger rätt att avsluta Kreditavtalet under vissa omständigheter. Dessa olika
              omständigheter beskrivs i denna punkt.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren har rätt att säga upp Kreditavtalet med två (2) månaders uppsägningstid.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren inte har utnyttjat någon del av Kreditutrymmet har Kreditgivaren rätt att säga
              upp Kreditavtalet till upphörande omedelbart utan föregående uppsägningstid. Kreditgivaren har
              också rätt att, under samma förutsättningar, säga upp den del av Kreditutrymmet som
              Kredittagaren ej har utnyttjat.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren har rätt att säga upp Kreditavtalet samt kräva återbetalning i förtid av
              utestående kapitalbelopp, räntor samt eventuella kostnader och avgifter om:
            </ModalParagraph>
            <ModalParagraph>
              a) Kredittagaren sedan mer än en månad är i dröjsmål med betalning av ett belopp som överstiger
              tio procent av Krediten, eller
            </ModalParagraph>
            <ModalParagraph>
              b) Kredittagaren sedan mer än en månad är i dröjsmål med betalning av ett belopp som överstiger
              fem procent av Krediten och dröjsmålet avser två eller flera Månadsbelopp som förfallit vid
              olika tidpunkter, eller
            </ModalParagraph>
            <ModalParagraph>
              c) Kredittagaren på annat sätt är i väsentligt dröjsmål med betalning.
            </ModalParagraph>
            <ModalParagraph>
              Om Kreditgivaren säger upp Kreditavtalet och begär förtida betalning enligt a), b) eller c) ovan
              gäller en uppsägningstid om fyra (4) veckor från det att meddelandet om uppsägningen kommer
              Kredittagaren tillhanda. Om Kreditgivaren kräver betalning i förtid enligt a), b) eller c) är
              Kredittagaren ändå inte skyldig att betala i förtid om Kredittagaren före utgången av
              uppsägningstiden betalar de belopp som förfallit jämte dröjsmålsränta enligt prisbilagan i dessa
              Allmänna villkor.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren förbehåller sig rätten att säga upp Kreditavtalet eller på annat sätt begränsa
              Kredittagarens nyttjande av Krediten eller Kreditutrymmet i händelse av att Kredittagaren upptar
              lån eller kredit hos Tredje Part och avser att nyttja sådant Kredit i syfte att lösa befintliga
              krediter hos Kreditgivaren. Sådan uppsägning sker i enlighet med uppsägningstid som anges ovan.
              Sådan uppsägning medför inte att Kredittagaren behöver betala Krediten i förtid.
            </ModalParagraph>
            <ModalParagraph>
              Om Kreditgivaren säger upp Kreditavtalet eller del av Kreditutrymmet kommer Kreditgivaren att
              skicka ett skriftligt meddelande om uppsägning till Kredittagarens konto hos www.fairlo.se.
              Kredittagaren kan när som helst ta del av Kreditgivarens eller Fairlos meddelanden till
              Kredittagaren på Mina sidor. Ett sådant meddelande anses ha kommit Kredittagaren tillhanda när
              Kredittagaren kan ta del av det.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Ångerrätt',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagaren har rätt att ångra sitt ingående av Kreditavtal inom 14 dagar räknat från den dag
              Kreditavtalet ingicks. Det innebär att Kredittagarens avtal med Fairlo upphör att gälla.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagare som avser att använda sin ångerrätt kan göra det genom att logga in på Mina sidor
              på www.fairlo.se och följa instruktionerna. Kredittagaren har från utnyttjandet av ångerrätten
              30 dagar på sig att återbetala eventuell utnyttjad Kredit samt upplupna räntor, räknat från den
              dag Kredittagaren kunnat nyttja Krediten till och med den dag Krediten återbetalas.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Överlåtelse av Kreditavtalet',
        answer: (
          <Stack>
            <ModalParagraph>
              Kreditgivaren äger rätt att överlåta, pantsätta fordringar samt överlåta samtliga rättigheter
              och skyldigheter under Kreditavtalet utan Kredittagarens godkännande.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren äger rätt att överlåta, pantsätta fordringar samt överlåta samtliga rättigheter
              och skyldigheter under Kreditavtalet utan Kredittagarens godkännande.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Standardiserad europeisk konsumentkreditinformation (”SEKKI”)',
        answer: (
          <ModalParagraph>
            Kreditgivaren ska innan Kreditavtalet ingås informera Kredittagaren om Krediten och dess löptid
            samt övriga uppgifter om Krediten i enlighet med vid var tid gällande lagstiftning om
            konsumentkrediter. Kredittagaren kan när som helst ta del av denna information genom att läsa
            SEKKI som finns på www.fairlo.se.
          </ModalParagraph>
        )
      },
      {
        question: 'Meddelanden',
        answer: (
          <Stack>
            <ModalParagraph>
              Om Kredittagaren ändrar namn, e-postadress eller telefonnummer ska Kredittagaren anmäla detta
              till Fairlo. Det kan Kredittagaren göra genom att logga in på Mina sidor på www.fairlo.se och
              följa instruktionerna.
            </ModalParagraph>
            <ModalParagraph>
              När Kreditavtalet ingås ger Kredittagaren Kreditgivaren och Fairlo rätt att skicka information
              och meddelanden till Kredittagaren via e-post, sms eller annat automatiskt system för
              individuell kommunikation. Information och meddelanden som skickas till Kredittagaren via
              elektronisk kommunikation anses ha nått Kredittagaren vid mottagandet. All kommunikation sker på
              svenska.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Behandling av personuppgifter',
        answer: (
          <>
            <ModalParagraph>
              Fairlo ansvarar för att behandling av personuppgifter sker i enlighet med GDPR och vid var tid
              gällande dataskydds- och integritetsskyddslagstiftning. Du kan läsa mer om Fairlos behandling av
              personuppgifter i Fairlos Personuppgiftspolicy som finns på www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Fairlo har utsett ett dataskyddsombud som ska övervaka att Fairlo följer reglerna om skydd av
              personuppgifter. Dataskyddsombudet ska fullgöra sitt uppdrag på ett oberoende sätt i förhållande
              till Fairlo. Du kommer i kontakt med Fairlos dataskyddsombud genom att maila dataskydd@fairlo.se
            </ModalParagraph>
          </>
        )
      },
      {
        question: 'Uppgifter till och från kreditupplysningsföretag och kreditregister',
        answer: (
          <ModalParagraph>
            Uppgift om lån, betalningsförsummelse eller kreditmissbruk samt upplysning om antal registrerade
            krediter och skuld kan komma att lämnas till kreditupplysningsföretag med beaktande av de regler
            som framgår av kreditupplysningslagen, om Kreditavtalet sagts upp på grund av gravt eftersatt
            betalning. Kreditupplysningsföretaget kan då komma att föra in uppgifter om lånet i ett särskilt
            kreditregister som endast kreditinstitut och värdepappersbolag har tillgång till.
          </ModalParagraph>
        )
      },
      {
        question: 'Ändring av villkor',
        answer: (
          <Stack>
            <ModalParagraph>
              Kreditgivaren förbehåller sig rätten att ändra de Allmänna villkoren och Kreditavtalet utan att
              Kreditavtalet sägs upp. Fairlo meddelar Kredittagaren om förändringar genom meddelande som
              Kredittagaren kan ta del av på Mina sidor på www.fairlo.se. Kredittagaren kan också alltid ta
              del av de allmänna villkoren på Fairlos hemsida www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren inte godkänner Kreditgivarens eller Fairlos förändringar har Kredittagaren rätt
              att avsluta Kreditavtalet enligt punkt 17. Om Kredittagaren inte godkänner ändringen senast före
              den dag ändringen börjar gälla stängs Kredittagarens konto av för utgående transaktioner.
              Kredittagaren kan då endast betala enligt Betalningsplanen samt göra Extraamorteringar. Om
              uppsägning inte görs anses Kredittagaren ha godkänt ändringarna.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Begränsning av Kreditgivarens och Fairlos ansvar',
        answer: (
          <ModalParagraph>
            Kreditgivaren eller Fairlo är inte ansvarig för skada som beror på lagbud, myndighetsåtgärd,
            krigshändelse, strejk, lockout, blockad, bojkott eller annan omständighet utanför Kreditgivarens
            eller Fairlos kontroll. Förbehållet i fråga om strejk, lockout, blockad och bojkott gäller även om
            Kreditgivaren eller Fairlo själv är föremål för eller vidtar sådan konfliktåtgärd. Skada som kan
            uppkomma i andra fall ska inte ersättas om Kreditgivaren eller Fairlon förfarit med normal
            aktsamhet. Kreditgivaren ansvarar inte i något fall för indirekta skador. Föreligger hinder för
            Kreditgivaren eller Fairlo att verkställa betalning eller vidta annan åtgärd på grund av
            omständigheter som anges ovan får åtgärden skjutas upp tills hindret har upphört.
          </ModalParagraph>
        )
      },
      {
        question: 'Klagomål',
        answer: (
          <Stack>
            <ModalParagraph>
              Om Kredittagaren är missnöjd bör denne i första hand kontakta Fairlos kundservice på
              www.fairlo.se.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren efter att ha kontaktat kundservice fortfarande inte är nöjd kan Kredittagaren
              upprätta ett skriftligt kundklagomål till Fairlos Kundombudsman via e-post på
              kundombud@fairlo.se. Ett formulär för detta finns på Fairlos hemsida www.fairlo.se. Fairlo
              hanterar klagomålen i enlighet med Finansinspektionens allmänna råd om klagomålshantering
              avseende finansiella tjänster till konsumenter.
            </ModalParagraph>
            <ModalParagraph>
              Fairlo vill upplysa om att Kredittagaren kan få hjälp och kostnadsfri vägledning från
              Konsumenternas Bank- och finansbyrå och Konsumenternas Försäkringsbyrå samt den kommunala
              konsumentvägledningen.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren är missnöjd med Fairlos beslut i ett enskilt ärende kan Kredittagaren få saken
              prövad av Allmänna reklamationsnämnden med postadress Box 174, 101 23 Stockholm, telefon 08-508
              860 00. Mer information om Allmänna reklamationsnämnden går att finna på dess hemsida
              www.arn.se.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Tillsynsmyndighet och tvist',
        answer: (
          <Stack>
            <ModalParagraph>
              Finansinspektionen, adress Box 7821, 103 97 Stockholm (www.fi.se) utövar tillsyn över
              Kreditgivaren gällande den kreditprövning Kreditgivaren utför.
            </ModalParagraph>
            <ModalParagraph>
              Marknadsföringen av Krediten följer vid var tid gällande regler om marknadsföring.
              Konsumentverket, adress Box 48, 651 02 Karlstad (www.konsumentverket.se), utövar tillsyn över
              Kreditgivaren gällande Kreditgivarens efterlevnad av marknadsföringslagen.
            </ModalParagraph>
            <ModalParagraph>
              All information om Krediten lämnas på svenska. Tvister med anledning av Kreditavtalet ska lösas
              av svensk domstol och enligt svensk rätt. Kreditgivaren eller Fairlo äger dock rätt att väcka
              talan vid domstolar eller myndigheter i annan jurisdiktion än Sverige.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Prisbilaga – Räntor och avgifter',
        answer: (
          <>
            <ModalParagraph>
              Kreditgivaren har rätt att ändra priserna i prisbilagan i enlighet med vad som framgår av de
              Allmänna villkoren. Kreditgivaren har enbart rätt att ändra den räntan i enlighet med punkt 11.
              Räntan framgår även av Kreditavtalet.
            </ModalParagraph>
            <ModalParagraph>
              Såväl årsräntan som anges nedan som avgifter hänförliga till Kreditavtalet läggs till
              låneskulden genom kapitalisering per den sista varje kalendermånad. Detta gäller dock inte
              inkassoavgifter eller andra avgifter enligt lagen om ersättning för inkassokostnader m.m.
            </ModalParagraph>
          </>
        )
      },
      {
        question: 'Ränta för utnyttjad kredit',
        answer: (
          <Stack>
            <ModalParagraph>
              Kredittagaren betalar enbart ränta för den del av Kreditutrymmet som faktiskt nyttjats av
              Kredittagaren inte för maximalt kreditutrymme enligt Kreditavtalet.
            </ModalParagraph>
            <ModalParagraph>
              Det första Uttaget Kredittagaren gör är kostnadsfritt. För varje tillkommande Uttag
              Kredittagaren gör tillkommer en avgift på 195 kronor.
            </ModalParagraph>
            <ModalParagraph>
              Kreditgivaren tar ut en månadsavgift enligt tabellen nedan under den tid Kreditavtalet med
              Kredittagaren är gällande.
            </ModalParagraph>
            <div style={{ overflowX: 'auto' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Kreditutrymme (kr)</th>
                    <th>Nominell årsränta</th>
                    <th>Månatlig avgift</th>
                  </tr>
                </thead>
                <tbody>
                  {tableValues.map(dataArray => (
                    <tr key={dataArray[0]}>
                      <td>{dataArray[0]}</td>
                      <td>{`${dataArray[1]}%`}</td>
                      <td>{formatCurrency(Number(dataArray[2]), { currency: 'SEK' })}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <ModalParagraph>
              Upphört kreditutrymme 100 000: 24% nominell årsränta samt 29 kronor månatlig avgift.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Ränta för utnyttjad kredit Fairlo Lösa',
        answer: (
          <Stack>
            <ModalParagraph>För Fairlo Lösa gäller en nominell årsränta om 29 %.</ModalParagraph>
            <ModalParagraph>
              Kredittagaren betalar enbart ränta för den del av Kreditutrymmet som faktiskt nyttjats av kund,
              inte för maximalt kreditutrymme enligt Kreditavtalet. Det tillkommer aldrig någon avgift för
              tjänsten.
            </ModalParagraph>
          </Stack>
        )
      },
      {
        question: 'Dröjsmålskostnader',
        answer: (
          <Translation>
            {t => (
              <ModalTableList>
                <li>
                  <Text as="span" weight="bold">
                    Påminnelsekostnad
                  </Text>
                  <ModalParagraph>{t('Common:money', { amount: 60 })}</ModalParagraph>
                </li>
                <li>
                  <Text as="span" weight="bold">
                    Inkassokostnad
                  </Text>
                  <ModalParagraph>{t('Common:money', { amount: 180 })}</ModalParagraph>
                </li>
                <li>
                  <Text as="span" weight="bold">
                    Dröjsmålsränta
                  </Text>
                  <ModalParagraph>
                    {t('Common:percentage', { percentage: 30 })} nominell årsränta över referensräntan
                  </ModalParagraph>
                </li>
                <li>
                  <Text as="span">Övriga kostnader</Text>
                  <ModalParagraph>
                    Vid överlämnande av fordran till Kronofogdemyndigheten tillkommer ytterligare vid var tid
                    gällande lagstadgade kostnader.
                  </ModalParagraph>
                </li>
              </ModalTableList>
            )}
          </Translation>
        )
      }
    ]
  },
  'en-GB': {
    name: 'Allmänna villkor',
    readMore: false,
    questions: []
  }
};

const TermsAndConditionsModal = () => {
  const { t } = useTranslation(['Common']);

  return (
    <Modal title={t('termsAndConditionsFairloEverydayLoans')}>
      <Stack>
        {termsAndConditions['sv-SE'].questions.map(question => (
          <div key={question.question}>
            <Stack>
              <TermsHeadingText as="h4">{question.question}</TermsHeadingText>
              <div>{question.answer}</div>
            </Stack>
          </div>
        ))}
      </Stack>
    </Modal>
  );
};

export default TermsAndConditionsModal;
