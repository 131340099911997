import { Colors, Icon, Text } from '@fairlo/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../../constants/marketingSiteRouteMap';
import { useIsTabletDown } from '../../../responsive';
import { CloseButton, Content, Wrapper } from './styled';
import useCookieConsent from './useCookieConsent';

export const ANIMATION_DURATION = 0.4; // seconds

const bannerVariants = {
  hidden: { y: '100%', transition: { duration: ANIMATION_DURATION } },
  visible: {
    y: 0,
    transition: {
      delay: 4, // Wait for initial page javascript so the animation looks smooth
      duration: ANIMATION_DURATION
    }
  }
};

const MotionWrapper = motion(Wrapper);

const CookieBanner = () => {
  const { t } = useTranslation(['CookieBanner', 'Common']);
  const isTabletDown = useIsTabletDown();
  const { hasConsent, giveConsent } = useCookieConsent();

  return (
    <AnimatePresence>
      {!hasConsent ? (
        <MotionWrapper
          role="alert"
          variants={bannerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Content>
            <Text>
              <Trans
                t={t}
                i18nKey={isTabletDown ? 'shortText' : 'longText'}
                components={{
                  anchor: (
                    <Text
                      as="a"
                      href={marketingSiteRouteMap.integrity}
                      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                        // Make sure consent is given before navigating to the privacy policy page
                        e.preventDefault();
                        giveConsent();
                        window.location.href = marketingSiteRouteMap.integrity;
                      }}
                      weight="medium"
                    />
                  )
                }}
              />
            </Text>

            <CloseButton onClick={() => giveConsent()} aria-label={t('Common:close')}>
              <Icon glyph="close" size={16} color={Colors.GRAY_900} aria-hidden="true" />
            </CloseButton>
          </Content>
        </MotionWrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default CookieBanner;
