import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const ApplicationEmailMismatch = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('ApplicationEmailMismatch.title')}>
      <ModalParagraph>{t('ApplicationEmailMismatch.info')}</ModalParagraph>

      <Modal.Actions>
        <Button onClick={closeModal}> {t('ApplicationEmailMismatch.action')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ApplicationEmailMismatch;
