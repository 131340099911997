import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import useMeQuery from '../../../hooks/useMeQuery';
import { selectUsername } from '../../../hooks/useMeQuery/selectors';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const WithdrawalNotAllowedPastCollectionFee = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  const { data: username } = useMeQuery({ select: selectUsername });

  return (
    <Modal title={t('WithdrawalNotAllowedPastCollectionFee.title', { username })}>
      <ModalParagraph>{t('WithdrawalNotAllowedPastCollectionFee.info')}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalNotAllowedPastCollectionFee;
