import { z } from 'zod';
import { AgreementStatus, CreditRejectReasons } from '../../../constants';
import { LevlaRejectReasons } from '../../../constants/RejectReasons';

export const createApplicationResponseSchema = z.object({
  applicationId: z.string(),
  orderId: z.string(),
  autoStartToken: z.string(),
  agreementId: z.string(), // Used for tracking
  userId: z.string() // Used for tracking
});

export const grantedSchema = z.object({
  status: z.literal(AgreementStatus.GRANTED),
  amountGranted: z.number(),
  jwt: z.string()
});

export const rejectedSchema = z
  .object({
    status: z.literal(AgreementStatus.REJECTED),
    userName: z.string()
  })
  .and(
    z
      .union([
        z.object({
          causeOfReject: z.enum([
            CreditRejectReasons.BANKID_ISSUE_DATE,
            CreditRejectReasons.EXCEED_NUMBER_OF_LOANS,
            CreditRejectReasons.EXISTING_CREDIT,
            CreditRejectReasons.EXISTING_LOAN,
            CreditRejectReasons.KALP_REJECTED,
            CreditRejectReasons.OVERDUE_PAYMENTS,
            CreditRejectReasons.TERMINATED_LOAN,
            CreditRejectReasons.USER_BLOCKED,
            CreditRejectReasons.TERMINATED_AGREEMENT,
            CreditRejectReasons.UC_SCORE_MISSING,
            CreditRejectReasons.UC_SERVICE_ERROR,
            'UNKNOWN'
          ])
        }),
        z.object({
          causeOfReject: z.literal(CreditRejectReasons.BISNODE_REJECTED),
          rejectCode: z
            .enum([
              'DR101',
              'DR102',
              'DR103',
              'DR104',
              'DR105',
              'DR106',
              'DR110',
              'DR113',
              'DR117',
              'DR202',
              'DR209',
              'DR210',
              'DR213',
              'DR300',
              'DS125',
              'D1012',
              'D1013',
              'D1022'
            ])
            .array()
        }),
        z.object({
          causeOfReject: z.literal(CreditRejectReasons.RECENT_BISNODE_CALL),
          dateToReapply: z.string()
        })
      ])
      .catch({ causeOfReject: 'UNKNOWN' })
  );

export const eligibilityCheckerFailedSchema = z.object({
  status: z.literal(AgreementStatus.ELIGIBILITY_CHECKER_FAILED)
});

export const levlaRejectedSchema = z
  .object({
    status: z.literal(AgreementStatus.LEVLA_REJECTED),
    userName: z.string()
  })
  .and(
    z
      .union([
        z.object({
          causeOfReject: z.enum([
            LevlaRejectReasons.DIRECT_DEBIT_MANDATE_NOT_FOUND,
            LevlaRejectReasons.ELIGIBILITY_CHECKER_REJECTED,
            LevlaRejectReasons.INTERNAL_SCORE,
            LevlaRejectReasons.MAX_PRODUCT,
            LevlaRejectReasons.ONGOING_CHARGE,
            LevlaRejectReasons.OVERDUE_PAYMENTS,
            LevlaRejectReasons.PAYMENTS_WITH_FEES,
            LevlaRejectReasons.TOO_MANY_INQUIRIES,
            LevlaRejectReasons.UC_SCORE_MISSING,
            LevlaRejectReasons.UC_SERVICE_ERROR,
            LevlaRejectReasons.PAYMENT_FREE_MONTH,
            'UNKNOWN'
          ])
        }),
        z.object({
          causeOfReject: z.literal(LevlaRejectReasons.RECENT_BISNODE_CALL),
          dateToReapply: z.string()
        })
      ])
      .catch({ causeOfReject: 'UNKNOWN' })
  );

const statusProductTierSchema = z.object({
  id: z.string(),
  amount: z.number(),
  interest: z.number(),
  effectiveInterest: z.number()
});

export const levlaOfferSchema = z.object({
  status: z.literal(AgreementStatus.LEVLA_OFFER),
  userName: z.string(),
  currentProductTier: statusProductTierSchema,
  offeredProductTier: statusProductTierSchema,
  jwt: z.string()
});

export const startApplicationResponseSchema = z
  .union([
    grantedSchema,
    rejectedSchema,
    eligibilityCheckerFailedSchema,
    levlaRejectedSchema,
    levlaOfferSchema,
    z.object({ status: z.literal('UNKNOWN') })
  ])
  .catch({ status: 'UNKNOWN' });

export const signOfferResponseSchema = z.object({
  orderId: z.string(),
  autoStartToken: z.string()
});

export const getApplicationApprovedResponseSchema = z.object({
  status: z.literal('APPROVED'),
  creditLimit: z.number(),
  nationalNumber: z.string(),
  email: z.string()
});

export const getApplicationResponseSchema = z.discriminatedUnion('status', [
  z.object({
    status: z.enum(['CREATED', 'EXPIRED', 'EVALUATION_IN_PROGRESS', 'EVALUATION_FAILED', 'FAILED'])
  }),
  getApplicationApprovedResponseSchema,
  z.object({
    status: z.literal('REJECTED'),
    rejectionReason: z.string()
  })
]);

export const acceptBrokerApplicationOfferResponseSchema = z.object({
  authToken: z.string()
});
