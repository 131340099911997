import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';
import API from '../../../api';
import { showErrorToast } from '../../../components/Toast';
import { ErrorId } from '../../../constants';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';
import useLogout from '../../../hooks/useLogout';
import useMeQuery from '../../../hooks/useMeQuery';
import { selectRemainingDebt } from '../../../hooks/useMeQuery/selectors';
import useModal from '../../../hooks/useModal';
import { CustomUseMutationOptions } from '../../../types/reactQuery';
import { parseApiError } from '../../../utils/error';

const useTerminateCreditMutation = (
  options: CustomUseMutationOptions<AxiosResponse<void>, { reason: string }> = {}
) => {
  const { t } = useTranslation('ErrorToast');
  const { openModal } = useModal();
  const logout = useLogout();
  const isLoggedIn = useIsLoggedIn();

  const { data: remainingDebt } = useMeQuery({ enabled: isLoggedIn, select: selectRemainingDebt });

  return useMutation({
    mutationFn: API.credit.terminateCredit,
    onSuccess: () => logout(),
    onError: error => {
      const { errorId } = parseApiError(error);

      if (errorId === ErrorId.AGREEMENT_HAS_DEBT) {
        openModal('AGREEMENT_HAS_DEBT', {
          payload: { remainingDebt }
        });
      } else {
        showErrorToast(t('defaultMessage'));
      }
    },
    ...options
  });
};

export default useTerminateCreditMutation;
