const english = {
  Common: {
    money: '{{amount, currency}}',
    moneyWithTrailingZeros: '{{amount, currency(displayTrailingZeros: true)}}',
    moneyWithoutSign: '{{amount, currency(displaySign: false)}}',
    percentage: '{{percentage}}%',
    currency: '£',
    contactUs: 'Contact us',
    new: 'NEW',
    back: 'Back',
    before: 'Before',
    after: 'After',
    ok: 'Ok',
    retry: 'Retry',
    tryAgain: 'Try again',
    count_ordinal_one: '{{count}}st',
    count_ordinal_two: '{{count}}nd',
    count_ordinal_few: '{{count}}rd',
    count_ordinal_other: '{{count}}th'
  },
  Metadata: {
    title: 'Fairlo: Fair Finance',
    description:
      'Credit that works for your everyday life, no matter how your situation changes. Apply for your credit line today!'
  },
  CommonFormLabels: {
    email: 'E-mail',
    mobile: 'Mobile number'
  },
  Button: {
    clear: 'Ok',
    tryAgain: 'Try again',
    done: 'Done'
  },
  Header: {
    openMenu: 'Open menu',
    closeMenu: 'Close menu',
    aboutUs: 'About Fairlo',
    customerService: 'Contact us',
    commonQuestions: 'Help centre',
    career: 'Careers',
    paymentPlan: 'My repayment plan',
    userProfile: 'My details',
    apply: 'Apply',
    login: 'Log in',
    home: 'Home',
    logout: 'Log out'
  },
  CookieBanner: {
    shortText: 'We use cookies.\nRead more in our <anchor>Cookie Notice</anchor>',
    longText:
      'We use cookies to help optimise our  website performance. You can read more about cookies in our <anchor>Cookie Notice</anchor>'
  },
  Modal: {
    AgreementHasDebt: {
      title: 'Repay your outstanding balance',
      text: 'Before you can end this agreement you need to ensure that your outstanding balance is £0. Your outstanding balance is {{amount, currency}}.',
      button: 'Repay now'
    },
    ApplicationEmailMismatch: {
      title: 'Sorry you’ve used a different email.',
      info: 'To apply you’ll need to use the same email you used to signup to the waitlist.',
      action: 'Try again'
    },
    ApplicationExistingUser: {
      title: 'Welcome back!',
      info: 'We can see that you already have a Fairlo account.',
      action: 'Login'
    },
    ApplicationExpired: {
      title: 'Your application has expired',
      info: 'Please complete the application form to reapply.',
      button: 'Reapply now'
    },
    DepositFail: {
      title: 'Something has gone wrong',
      info: "Your additional repayment didn't go through, please try again or make a manual repayment.",
      payManually: 'Pay manually',
      tryAgain: 'Try again'
    },
    DepositSuccess: {
      title: 'Repayment successful',
      info: 'For a full summary go to your Repayment Plan view. Your outstanding balance will be updated once the repayment has been received.',
      depositAmount: 'Total repaid',
      monthlyAmount: 'New monthly repayment',
      button: 'Done'
    },
    DirectDebitChargeInProgress: {
      title: 'Your Direct Debit is being processed',
      info: 'As soon as your monthly repayment of {{chargeInProgressAmount, currency}} has gone through successfully, you will be able to make withdrawals as usual again. Thanks for your patience.'
    },
    DirectDebitMandateNotFound: {
      title: 'Your Direct Debit mandate is missing',
      info: 'Your Direct Debit has been removed, you will be able to make further withdrawals once you’ve set this up. To do this please follow the notification on the home screen.'
    },
    DirectDebitSetupFailed: {
      title: 'Something has gone wrong',
      info: 'Your Direct Debit Mandate wasn’t submitted please try again.'
    },
    BankAccountUnableToValidate: {
      title: 'Unable to validate your account',
      info: 'Please check you entered your account details correctly.'
    },
    BankAccountNotSupported: {
      title: "Your account isn't supported",
      info: "We were unable to validate your account details because your account isn't supported. Please try a different account."
    },
    ConfirmWithdrawal: {
      title: 'Confirm your withdrawal of {{withdrawalAmount, currency}}?',
      info: 'Transferred into your nominated account.',
      infoWithFees:
        'Here is your updated monthly repayment amount and your new outstanding balance that includes the fee of {{withdrawalFee, currency}}.',
      infoTitle1: 'Monthly repayment',
      infoTitle2: 'New outstanding balance',
      button: 'Withdraw {{withdrawalAmount, currency}}'
    },
    OverdueChargeInProgress: {
      title: 'Monthly repayment overdue',
      info: 'Until your Direct Debit has successfully completed you won’t be able to make further withdrawals.'
    },
    Reapply: {
      title: 'Please reapply to proceed',
      button: 'Reapply now'
    },
    ConfirmManualDeposit: {
      title: 'Over to you!',
      info: 'Your outstanding balance will be updated as soon as we receive your manual additional repayment. Once you have made the bank transfer it can take up to 5 working days to update.',
      button: 'Ok'
    },
    WithdrawalBlocked: {
      title: '{{username}}, unfortunately you cannot make withdrawals at the moment',
      info: "We at Fairlo take responsible lending seriously and our assessment is that it would not be responsible to allow more withdrawals. We cannot currently know when you will again be able to make withdrawals, but promise to notify you as soon as the situation changes. Until then, it's super important that you stick to your payment plan. Your friends at Fairlo"
    },
    WithdrawalInProgress: {
      title: "It is on it's way, but the transaction is taking a little longer than usual",
      info: 'Sometimes transactions can take up to 2 hours, by typically this is much faster. Do not hesitate to get in touch if you have any questions.'
    },
    WithdrawalNotPossible: {
      title: 'Remaining limit below minimum withdrawal',
      info: 'Your remaining credit limit of {{amountNotUsed, currency}} is below the minimum withdrawal of $t(Common:money, { "amount": 100 }).'
    },
    ConfirmEndAgreement: {
      title: 'Are you sure?',
      info: 'Are you sure you want to end your agreement, this can’t be reversed and you will lose access to your Fairlo account.',
      feedbackInfo:
        'To help us improve our service, please could you explain why you are leaving Fairlo (max 300 characters).',
      feedbackLabel: 'Feedback',
      feedbackPlaceholder: 'Help us improve and tell us why you’re leaving',
      button: 'End agreement'
    },
    WithdrawalFail: {
      title: 'Something has gone wrong',
      info: "Your withdrawal wasn't successful, please try again."
    },
    WithdrawalSuccess: {
      title: 'Withdrawal successful',
      info: 'For a full summary go to your Repayment Plan view.',
      fairnessReceiptButton: 'Fairness Receipt',
      button: 'Done'
    },
    ChangeMonthlyPaymentSuccess: {
      title: 'Your monthly repayment is updated',
      info: 'For a full summary go to your Repayment Plan view.',
      listItemTitle: 'Monthly amount'
    },
    ChangeMonthlyPaymentFailed: {
      title: 'Something has gone wrong',
      info: 'We couldn’t update your monthly repayment. Please try again.'
    },
    OverduePayment: {
      title: 'An amount is overdue',
      info: 'This includes all overdue monthly repayments and any additional interest accrued on the balance up to today due to late repayment',
      infoItemTitle1: 'Overdue monthly repayments',
      infoItemTitle2: 'Additional interest accrued',
      infoItemTitle3: 'Total overdue amount due',
      button: 'Pay {{amount , currency}}'
    },
    WaitlistSuccess: {
      title: 'We’ll be in touch soon {{firstName}}.',
      info: 'Congratulations your on the list to be one of the first to access Fairlo in the UK.',
      button: 'Thanks'
    },
    WaitlistSubscribed: {
      title: 'Hi {{firstName}}, you’re already on the list.',
      info: 'You will be one of the first to access Fairlo in the UK. We’ll be in touch soon.',
      button: 'Thanks'
    }
  },
  ErrorInfo: {
    title: 'Oops! Something went wrong',
    text: 'Please go back to the home page and try again',
    button: 'Take me home'
  },

  FormErrors: {
    required: 'This is required',
    alphabeticalOnly: 'Please only use letters',
    alphanumericOnly: 'Please only use letters and numbers',
    positiveOnly: 'This cannot be negative',
    invalidEmail: 'Please enter a valid email address',
    invalidMobileNumber: 'Please enter your mobile number as +447xxxxxxxxx',
    invalidCurrency: 'Please enter a valid amount',
    maxLength: 'Please only use up to {{length}} characters',
    maxAmount: 'Please check this is correct',
    invalidDigitsLength: 'Must be {{length}} digits',
    numericOnly: 'Please only use numbers',
    numbersInRange: 'Please only use number in the range of {{minValue}} - {{maxValue}}'
  },
  CreditApplicationUK: {
    progressBarText: 'Step {{step}} of {{totalSteps}}',
    waitlist: {
      title: 'Be one of the first to apply to Fairlo.',
      subTitle:
        'Fill in your details below to request early access and apply for your life-happens credit buffer.',
      firstNameLabel: 'First Name',
      emailLabel: 'Email address',
      termsAndConditionsText:
        'By continuing you agree to our <privacyNoticeButton>Privacy Notice</privacyNoticeButton> and to be contacted when you can apply',
      button: 'Request access'
    },
    continueButton: 'Continue',
    personalDetailsForm: {
      title: 'Apply Now',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      birthDayLegend: 'Date of Birth',
      birthYearLabel: 'Year',
      birthYearPlaceholder: 'YYYY',
      birthMonthLabel: 'Month',
      birthMonthPlaceholder: 'MM',
      birthDayLabel: 'Day',
      birthDayPlaceholder: 'DD',
      emailLabel: 'Email address',
      termsAndConditionsText:
        'By continuing you agree to our <privacyNoticeButton>Privacy Notice</privacyNoticeButton> and <termsAndConditionsButton>Terms & Conditions</termsAndConditionsButton>',
      errors: {
        invalidBirthYear: 'Invalid year',
        invalidBirthMonth: 'Invalid month',
        invalidBirthDay: 'Invalid day',
        invalidDate: 'Please enter a valid date'
      }
    },
    addressForm: {
      title: 'Address Details',
      heading1: 'We need 3 years UK address history.',
      heading2: 'Please provide your previous address.',
      heading3: 'We just need one more previous address.',
      buildingNumberLabel: 'Building number',
      buildingNameLabel: 'Building name',
      streetLabel: 'Street',
      postCodeLabel: 'Post Code',
      townLabel: 'Town / City',
      longTimeAddressLegend: 'Have you lived here since {{date}}?',
      longTimeAddressYes: 'Yes',
      longTimeAddressNo: 'No',
      autocompleteLabel: 'Address',
      autocompletePlaceholder: 'Start typing your address',
      switchToManualAddress: 'Enter address manually',
      selectAnotherAddress: 'Select another address',
      editAddress: 'Edit address',
      errors: {
        invalidPostCode: 'Please enter a valid post code',
        buildingNameOrNumberRequired: 'Please enter a building name or number',
        residentialAddressRequired: 'Please enter a residential address',
        editAddressManually: 'Please edit the address manually',
        serviceError: 'Something has gone wrong. Please enter an address manually.'
      }
    },
    incomeForm: {
      title: 'Income',
      employmentStatusLegend: 'Employment Status',
      employmentStatusOptionFullTime: 'Full-time',
      employmentStatusOptionPartTimeFixedIncome: 'Part-time - Fixed Salary',
      employmentStatusOptionPartTimeVariableIncome: 'Part-time - Variable Pay',
      employmentStatusOptionSelfEmployed: 'Self Employed',
      employmentStatusOptionRetired: 'Retired',
      employmentStatusOptionUnemployed: 'Unemployed',
      monthlyIncomeLegend: 'Enter the amount paid into your account each month.',
      monthlyIncomeLabel: 'Your monthly income after tax',
      monthlyIncomeWarning:
        'Your income looks high, check you have entered the amount paid into your account each month.',
      peopleContributingToHouseholdLegend:
        'How many adults in your household earn income and contribute to bills?',
      peopleContributingToHouseholdOption1: 'Only me',
      peopleContributingToHouseholdOption2: 'Two',
      peopleContributingToHouseholdOption3: 'Three',
      peopleContributingToHouseholdOption4: 'Four+'
    },
    outgoingsForm: {
      title: 'Outgoings',
      subtitle: 'Please enter the amount which you personally pay each month for the following outgoings.',
      monthlyRentLabel: 'Monthly rent / mortgage',
      monthlyLoanLabel: 'Monthly loan or credit repayments',
      monthlyOutgoingsLabel: 'Other monthly outgoings',
      numberOfDependentsLegend: 'How many dependents do you have?',
      numberOfDependentsOption0: '0',
      numberOfDependentsOption1: '1',
      numberOfDependentsOption2: '2',
      numberOfDependentsOption3: '3+',
      footerText: "We will perform a soft search<br />this won't impact your credit score.",
      submitButton: 'Submit'
    },
    status: {
      loaderText: 'We’re just assessing your application. It will only take a few moments.'
    },
    manualApproval: {
      title: 'We need a few more details',
      text1:
        'You are almost there, we just need a few more details before we can proceed with your application.',
      text2: 'Please reply to the email we have just sent, within 5 days, with the following documents:',
      bullet1:
        'A statement from the last 3 months showing all account details including name, address, sort code and account number',
      bullet2: 'A picture your driving licence (front), or passport (picture page)',
      bullet3: 'The reason',
      text3:
        'Check your spam folder or <contactButton>contact us</contactButton>, if you haven’t received our email.',
      button: 'Got it',
      reason: {
        selfEmployedIncomeVerification: 'Your 2 most recent SA302 tax returns',
        partTimeVariableIncome: 'Your last 3 payslips',
        politicallyExposedPerson: 'A payslip from the last 3 months'
      }
    },
    manualApprovalError: {
      title: 'We need a few more details',
      text1: 'We just need a few more details before we can proceed with your application.',
      text2:
        'Please check your emails and reply to the email from hello@fairlo.co.uk with the documents requested.',
      text3:
        'Check your spam folder or <contactButton>contact us</contactButton>, if you haven’t received our email.',
      button: 'Got it'
    },
    rejections: {
      title: "Unfortunately, we can't approve you today",
      genericRejectionText: 'While we can’t help you today, we hope we may support you in the future.',
      genericRejectionText2:
        'Our decision is based on the details you provide, along with information we receive from credit reference agencies (Experian and TransUnion), for more information please see our <PrivacyNoticeLink>Privacy Notice</PrivacyNoticeLink> or contact <ExperianLink>Experian</ExperianLink> and <TransUnionLink>TransUnion</TransUnionLink>.',
      overdueRejectionText:
        'This is because you have overdue invoices on your account that need to be settled before you can proceed with any further applications.',
      userBlockedText:
        'This is because you have been blocked from using Fairlo. This could be due to a number of reasons for example; your account going into default, or at your request.',
      questionsText:
        'If you have any questions, or wish to appeal please reach out to our customer support team at <customerServiceMail />.',
      buttonHome: 'Take me home',
      text1: "Based on your income and outgoings you aren't able to afford the minimum repayments.",
      text2: 'This is because your credit score is outside of our lending criteria.',
      text3:
        "Based on your current credit commitments, it wouldn't be responsible for us to provide credit to you at this time.",
      text4: "This is because you don't meet our eligibility requirements for a credit line.",
      action1:
        'Money Helper have an <MoneyHelperLink>online budgeting tool</MoneyHelperLink> to help you assess your finances.',
      action2:
        'Before we (and most other lenders) can offer further credit to you, you will need to catch up with your outstanding credit commitments.',
      action3:
        'If you are struggling to meet your current commitments then Money Helper have some <MoneyHelperTroublesLink>support and guidance</MoneyHelperTroublesLink> which might be useful.',
      action4:
        'You can improve your credit history to improve your chances of being accepted by lenders in the future. Money Helper has more information which may be useful <MoneyHelperImproveLink>here</MoneyHelperImproveLink>.',
      action5:
        'To be eligible for Fairlo you must:\n - be at least 20 years old\n - have a declared annual income of at least £20,000\n - be a current resident in the UK for a minimum of 12 months\n - have a UK bank or building society account able to make repayments by Direct Debit\n - not have any County Court Judgments (CCJs) or Debt Relief Orders against you and/ or be subject to an Individual Voluntary Arrangement (IVA)',
      RecentApplication: {
        text: 'This is because you made a recent application which has been declined.',
        action:
          "Here is a <MoneyHelperLink>link to Money Helper's</MoneyHelperLink> online budgeting tool to help you assess your finances before applying for credit in the future."
      },
      BlockedStatus: {
        text: 'This is because you have been blocked from using Fairlo. This could be due to a number of reasons for example; your account going into default, or at your request.'
      },
      OverdueStatus: {
        text: 'This is because you have overdue invoices on your account.',
        action: 'These need to be settled before you can proceed with any further applications.'
      },
      CII: {
        action:
          "Here is a <MoneyHelperLink>link to Money Helper's</MoneyHelperLink> online budgeting tool to help you assess your finances and see if you can repay some of your existing debt before applying."
      },
      Bankrupt_IVA: {
        text: 'This is because you have a Bankruptcy (or similar) on your credit report.'
      },
      CCJ: {
        text: 'This is because you have a CCJ (or similar) on your credit report.'
      },
      Default: {
        text: 'This is because you have a default on your credit report.'
      },
      Delinquency: {
        text: 'This is because you have an overdue account(s) on your credit report.'
      },
      RecentAccountsOpened: {
        text: 'This is because you have opened too many credit accounts elsewhere in the last 12 months.',
        action:
          'This could demonstrate to lenders that you may be struggling to keep up with current commitments.',
        action1:
          'If you are struggling to meet your current commitments then Money Helper have some <MoneyHelperDeptTroubles>support and guidance</MoneyHelperDeptTroubles> which might be useful.'
      },
      ThinFile: {
        text: "This is because your credit report doesn't have enough information on it for us to make a creditworthiness assessment.",
        action:
          'We are looking to expand our offering in the near future, but for now you can build your credit history with services.',
        action1:
          'Money Helper have information on how to improve your credit score <MoneyHelperImproveLink>here</MoneyHelperImproveLink>.'
      },
      BureausUnavailable: {
        text: "Based on the information you provided we can't find your credit report at the credit reference agencies.",
        action: "If you think there's something wrong, please reach out to them through the links below."
      },
      IncomeVerification: {
        text: 'This is because were unable to verify your income.',
        action:
          'If you have recently changed jobs or salary then it can take time for this to flow through to credit reference agencies.'
      }
    },
    congratulations: {
      title: 'Congratulations',
      subtitle: 'You are approved. Select your credit limit'
    },
    creditLimitForm: {
      title: 'Select your free credit limit',
      subTitle:
        'Only pay for what you use for the time you use it, by withdrawing any amount up to your credit limit straight into your bank account at any time.',
      submitButton: 'Select',
      tierCard: {
        amount: '<symbol>£</symbol> {{amount, currency(displaySign: false)}}',
        apr: 'APR',
        text: 'Example if full amount withdrawn',
        maxDuration: 'Max duration:',
        maxDurationValue: '{{period}} months',
        monthlyAmount: 'Monthly amount:',
        totalCost: 'Max total cost:'
      }
    },
    mobileVerification: {
      title: 'Verify your mobile number',
      sendMobileCode: {
        text: 'This allows you to access your account',
        inputLabel: 'Mobile number',
        button: 'Verify',
        mobileNumberNotAccepted:
          'This mobile number cannot be accepted. Please check you entered the number correctly.'
      },
      verifyMobileCode: {
        text1: 'We’ve sent a code to <strong>{{mobileNumber}}</strong>',
        text2: 'Enter it below'
      },
      rejection: {
        title: "Unfortunately we can't progress your application",
        text1: "This is because we couldn't verify your phone number belonged to you.",
        text2:
          'If you have any questions, you can reach out to our customer support team at <customerServiceMail />.',
        button: 'Take me home'
      }
    },
    documents: {
      title: 'Key documents',
      subTitle: 'Please review and sign the following documents to complete signup.',
      summary: 'Fairlo Summary',
      creditInformation: 'Pre-Contract Credit Information',
      creditAgreement: 'Agreement and T&Cs',
      text: 'We will email you a copy of these documents once signed. To download an unsigned copy click: <summary>Summary</summary>, <creditInformation>PCCI</creditInformation>, <creditAgreement>Agreement</creditAgreement>, <termsAndConditions>T&Cs</termsAndConditions>',
      footerText: 'Fairlo and your repayments will be visible on your credit history.',
      button: 'Confirm',
      form: {
        scrollText: 'Scroll to the bottom to sign',
        signButton: 'Sign',
        backButton: 'Back to agreements'
      }
    },
    success: {
      heading: 'All done!',
      refuseButton: "No, I don't mind if I miss out",
      acceptButton: 'Yes please!'
    }
  },
  ErrorToast: {
    defaultMessage:
      'Something has gone wrong, please try again. If the problem continues contact us at hello@fairlo.co.uk'
  },
  Login: {
    LoginForm: {
      title: 'Login to Fairlo',
      emailLabel: 'Email address',
      button: 'Log in',
      emailNotFound: "Sorry this email wasn't found."
    },
    AccountLocked: {
      title: 'Oops! Your account is locked',
      text1:
        'We have locked your account for 3 hours because you have requested too many one time passcodes.',
      text2: 'You can try to login again in 3 hours.',
      text3: 'This is a security feature to prevent unauthorised access to your account.'
    },
    Authenticate: {
      title: 'Enter the one time passcode',
      text: 'Sent to the mobile phone number on your account.'
    }
  },
  OTPVerification: {
    codeTriesLeft: 'Request new code ({{count}} left)',
    countDown: '{{seconds, relativeTime(second)}}',
    resendButton: 'Resend',
    wrongCode: 'Passcode invalid, please try again or request a new code.',
    codeExpired: 'Passcode expired, please request a new code.',
    maxTriesReached: 'Passcode expired due to too many attempts, please request a new code.'
  },
  BankAccount: {
    DirectDebitSetup: {
      title: 'Set-up your Direct Debit mandate',
      description:
        'This is the instruction to your bank or building society to repay by Direct Debit. Please check these details are correct before clicking Set-up.',
      name: 'Name',
      accountNumber: 'Account number',
      sortCode: 'Sort code',
      referenceNumber: 'Reference number',
      repaymentDate: 'Monthly repayment date',
      directDebitTerms: 'Direct Debit Guarantee',
      confirmText:
        'By clicking Set-up you confirm that you have the authority to setup a Direct Debit on this account',
      statementText: 'Fairlo UK Limited will appear on your statement.',
      submitButton: 'Set-up'
    },
    Setup: {
      title: 'Enter your nominated account details',
      info: 'To make withdrawals and repayments you need to set up your nominated account.',
      sortCodeLabel: 'Sort code',
      sortCodePlaceholder: '00-00-00',
      accountNumberLabel: 'Account number',
      accountNumberPlaceholder: '12345678',
      submitButton: 'Submit',
      dontHaveDifferentAccount: 'Don’t have a different account',
      loader: 'We are verifying your account. This will only take a moment.'
    },
    RepaymentDate: {
      title: 'Set your repayment date',
      text1: 'This is typically the date you are paid your salary.',
      text2: 'Repayments due on weekends and bank holidays will be made the next working day.',
      label: 'Enter the day of the month (1-31)',
      button: 'Set repayment date'
    }
  },
  Credit: {
    cards: {
      summaryTitle: 'Your credit limit for when you need it'
    }
  },
  Withdrawal: {
    description: 'Select the amount you would like to withdraw into your nominated account.',
    mobileButton: 'Withdraw {{amount, currency}}',
    desktopButton: 'Withdraw {{amount, currency}}',
    desktopSliderTitle: 'Slide to choose amount'
  },
  Navigation: {
    deposit: 'Additional Repayment',
    monthlyPayment: 'Monthly Repayment',
    withdrawal: 'Withdrawal'
  },
  Products: {
    cards: {
      usageSummary: {
        total: 'Credit limit:',
        used: 'Outstanding balance:',
        monthlyAmount: 'Monthly repayment:',
        interest: 'APR:',
        nextChargeLabel: 'Next monthly repayment:',
        nextChargeDate: '{{chargeDate, datetime(month: short; day: numeric)}}',
        nextChargeOngoing: 'requested'
      },
      manualApprovalAccountCard: {
        title: 'Check your email',
        body: 'You are almost there, we just need a few pieces of information to set up your nominated account. Reply to our email.'
      },
      setupNominatedAccountCard: {
        title: 'Set Up Nominated Account',
        body: 'We need to set up the account where you will receive any withdrawals and make repayments from. Click here.'
      },
      missingDirectDebitCard: {
        title: 'Missing Direct Debit',
        body: 'Your direct debit mandate is missing. Please click here to set it up to access to your credit limit.'
      },
      overdue: {
        title: 'Repayment Overdue',
        body: 'You have an overdue balance of {{amount, currency}}. Please click here to catch up and access your credit limit.'
      },
      transactionPending: {
        title: 'Transaction Pending',
        body: 'Once your previous transaction is confirmed access to your credit limit will be enabled'
      },
      transactionDelayed: {
        title: 'Transaction Delayed',
        body: 'Once your previous transaction is confirmed access to your credit limit will be enabled'
      },
      monthlyRepaymentPendingCard: {
        title: 'Monthly Repayment Pending',
        body: 'Your Direct Debit of {{amount, currency}} will be made on {{date, datetime(month: short; day: numeric)}}. Any additional repayments will not replace this repayment.'
      },
      monthlyRepaymentRetryCard: {
        title: 'Monthly Repayment Retry',
        body: 'Your Direct Debit will be retried for {{amount, currency}} on {{date, datetime(month: short; day: numeric)}}. Any additional repayments will not replace this repayment.'
      }
    },
    features: {
      spinWheel: 'Spin the wheel to select the amount',
      desktopSliderTitle: 'Slide to choose amount'
    },
    featureDetails: {
      withdrawalFee: 'Withdrawal fee {{amount, currency}}',
      includingFees: 'incl. withdrawal fee',
      principalLeftLabel: 'New outstanding balance:',
      monthlyRepaymentLabel: 'New monthly repayment:',
      monthlyRepaymentDetails: '{{monthlyAmount, currency}} for {{period}} mths',
      interestLeftLabel: 'Total interest due at {{percentage}}%:',
      infoText: 'Change repayments anytime to reduce interest cost'
    }
  },
  Deposit: {
    description:
      'Select the amount you would like to repay today, you still need to make your monthly repayment.',
    sliderTitle: 'Slide to choose amount',
    depositButton: 'Repay {{amount, currency}}',
    depositSummary: {
      title: 'Additional Repayment Summary',
      info1: 'This does not replace your monthly repayment.',
      from: 'From:',
      bankLabel: 'Bank',
      accountNumberLabel: 'Account Number',
      sortCodeLabel: 'Sort Code',
      to: 'To:',
      accountNameLabel: 'Account Name',
      paymentReferenceLabel: 'Payment Reference',
      amountLabel: 'Amount',
      info2: 'Only repayments from your nominated account will be accepted.',
      info3:
        'By continuing you are permitting our partner Modulr FS Limited to initiate a payment from your bank account and also agree to their <termsAndConditionsLink>T&C</termsAndConditionsLink> and <privacyPolicyLink>privacy policy</privacyPolicyLink>',
      button: 'Confirm'
    },
    manualDepositDetails: {
      title: 'Manual Repayment Details',
      info1:
        "You'll need to make a bank transfer from your nominated account using the details below.\nThis doesn't replace your monthly repayment.",
      from: 'From:',
      bankLabel: 'Bank',
      sortCodeLabel: 'Sort code',
      accountNumberLabel: 'Account number',
      to: 'To:',
      accountNameLabel: 'Account name',
      paymentReferenceLabel: 'Payment reference',
      amountLabel: 'Amount',
      info2: 'Only payments from your nominated account will be accepted.',
      button: 'Continue'
    },
    maxAmountText:
      'Congrats! This will pay off your total outstanding balance plus {{amount, currency}} interest accrued to today. You will not have any future monthly repayments.'
  },
  MonthlyPayment: {
    description: 'Select the amount you would like to repay each month.',
    changeMonthlyPaymentButton: 'Change amount to {{amount, currency}}',
    desktopSliderTitle: 'Slide to choose amount'
  },
  PaymentProvider: {
    willRedirect: 'We’re securely taking you to your bank. Your security details won’t be shared with us.'
  },
  UserProfile: {
    title: 'My details',
    contactInformation: {
      title: 'Contact information',
      currentAddress: 'Current address',
      addressForm: {
        buildingNumberLabel: 'Building number',
        buildingNameLabel: 'Building name',
        streetLabel: 'Street',
        postCodeLabel: 'Post Code',
        townLabel: 'Town / City'
      }
    },
    endAgreement: 'End my agreement'
  },
  EndAgreement: {
    title: 'End your agreement',
    info: "<P>You are able to end your agreement at any time when your outstanding balance is £0.</P><P>When your outstanding balance is £0 you don't pay any interest, but have access to credit up to your limit at any time in the future.</P><P>If you formally end your agreement today then you will no longer be able to access your credit limit.</P>",
    button: 'End agreement'
  },
  MyCredit: {
    whatDoYouWantToDo: 'What do you want to do?'
  },
  PaymentPlanTable: {
    date: 'Date',
    transaction: 'Action',
    amount: 'Amount',
    eventsUpcoming: 'Upcoming repayments',
    status: 'Status',
    creditTitle: '',
    debtConsolidationTitle: '',
    amountToPay: 'To pay',
    totalAmount: 'Total to pay',
    noTransactionsMsg: 'You haven’t made a transaction',
    noPaybackPlansMsg: 'You have no future payments',
    paybackPlanWillBeUpdated:
      'You currently have a payment free month. Your payment plan will be updated when you make your next payment',
    InvoiceStatus: {
      PAID: 'Paid',
      OVERDUE: 'Overdue',
      CHARGE: 'Pending'
    },
    PaybackItemType: {
      PAYBACK_PRINCIPAL: 'Principal',
      PAYBACK_INTEREST: 'Interest',
      EXTENDED_DUE_DATE_INTEREST: 'Interest on deferred invoice',
      OVERDUE_FEE: 'Reminder',
      DEBT_COLLECTION_FEE: 'Collection',
      DOUBLE_OVERDUE_FEE: '',
      DEBT_COLLECTION_INTEREST: 'Interest on collection invoice',
      REMIT: 'Remitted',
      ACCRUED_INTEREST: 'Accrued interest',
      REMAINING_AMOUNT_WRITTEN_OFF: 'Amount written off',
      UNKNOWN: 'Unknown',
      TERMINATION: 'Termination'
    },
    TransactionItem: {
      PAY_EXTRA: 'Additional Repayment',
      COMPLETE_LOAN: 'Additional Repayment',
      PAYBACK: 'Monthly amount',
      BULK_PAYBACKS: 'Monthly amount',
      PAYOUT: 'Withdrawal',
      ADMIN_PAY_EXTRA: 'Additional Repayment',
      SOLD_BAD_DEBT: 'Sold Bad Debt',
      OVERPAYMENT: 'Additional Repayment',
      REMAINING_AMOUNT_WRITTEN_OFF: 'Amount written off',
      DISBURSEMENT: 'Withdrawal',
      NORDISKA_TRANSFER: '',
      CREDIT_LOSS: 'Credit Loss',
      PAYMENT_FREE_MONTH: 'Payment Free Month'
    }
  },
  FairnessReceipt: {
    heading: 'Fairness Receipt',
    description:
      'See a breakdown of what you pay and what we earn. Ensure your terms are fair, and understand the cost of the money you borrow. You can freely make changes to lower cost or extend time.',
    withdrawalAmount: 'Withdrawal amount',
    newTotalBalance: 'New total balance',
    whatYouPay: 'What you pay',
    interest: 'Interest for {{months}} months',
    principal: 'Principal',
    whatWePay: 'What we pay',
    funding: 'Funding',
    lossProvision: 'Loss provision',
    servicing: 'Servicing',
    labour: 'Labour',
    totalAmount: 'Total amount',
    whatWeMake: 'What we make',
    done: 'Done'
  },
  ApiErrors: {
    loginTookLong: 'Whoops, login timed out, please try again. If the problem persists, please contact us.',
    inactivity:
      'Whoops, you were logged out due to inactivity, please log back in to continue. If the problem persists, please contact us.'
  },
  Transactions: {
    title: 'Repayment Plan & History',
    planTab: 'Plan',
    historyTab: 'History',
    noHistory: 'There are no transactions',
    noPlanNewUser: 'You haven’t made a withdrawal',
    noPlan: 'You don’t have any upcoming payments',
    detailsTitle: 'Transaction Item',
    transaction: {
      type: {
        DEPOSIT: 'Deposit',
        WITHDRAW: 'Withdrawal',
        PAYBACK: 'Repayment',
        REFUND: 'Refund'
      },
      product: {
        CREDIT: ''
      },
      status: {
        INCOMPLETE: 'Upcoming',
        COMPLETE: 'Paid',
        COMPLETE_DEPOSIT: 'Received',
        COMPLETE_WITHDRAW: 'Paid Out',
        CHARGE: 'In-progress',
        OVERDUE: 'Overdue'
      },
      detailType: {
        PRINCIPAL: 'Principal',
        INTEREST: 'Interest',
        OVERDUE_INTEREST: 'Late interest',
        DIRECT_DEBIT_MISSING_FEE: 'Missing Direct Debit',
        REMIT: 'Correction',
        UNKNOWN: 'Unknown',
        TERMINATION: 'Termination'
      },
      dueDate: 'Due date',
      completionDate: 'Payment date',
      statusTitle: 'Status',
      totalAmount: 'Total amount'
    }
  },
  Maintenance: {
    title: 'Excuse the mess!',
    text1: 'The website is offline for maintenance.',
    text2: 'We are updating our database and will be back online shortly.'
  }
};

export default english;
