import { Colors, Text } from '@fairlo/ui-kit';
import styled from 'styled-components';

import { Container, zIndex } from '../../../styled';

export const Wrapper = styled.div`
  position: fixed;
  z-index: ${zIndex.COOKIE_BANNER};
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 0;
  border-top: 1px solid ${Colors.GRAY_200};
  white-space: pre-line;
`;

export const Content = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${Text} {
    flex: 1;
    font-weight: 500;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
`;
