export const AnalyticsEvents = {
  ADD_TO_CART: 'add-to-cart',
  APPLICATION_PRICE_MENU_CLICK: 'application-price-menu-click',
  DCS_DEPOSIT_SUCCESS: 'DCS_DEPOSIT_SUCCESS',
  DEPOSIT_OVERDUE_SUCCESS: 'DEPOSIT_OVERDUE_SUCCESS',
  DEPOSIT_REQUEST: 'DEPOSIT_REQUEST',
  DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',
  INITIATE_CHECKOUT: 'initiate-checkout',
  LEVLA_GRANTED: 'levla-granted',
  MONTHLY_PAYMENT_CHANGED: 'MONTHLY_PAYMENT_CHANGED',
  NAVIGATION_MENU_CLOSED: 'navigation-menu-closed',
  NAVIGATION_MENU_OPENED: 'navigation-menu-opened',
  PURCHASE_DENIED: 'purchase-denied',
  /**
   * This event name is used for Google Ads / Facebook Pixel conversion tracking in Segment, do not
   * change without updating in Segment
   * Tickets:
   * - https://fairloo.atlassian.net/browse/FCSE-4758
   * - https://fairloo.atlassian.net/browse/FCUK-1974
   */
  PURCHASE_GRANTED: 'purchase-granted',
  SUBMIT_CREDIT_APPLICATION_STEP: 'submit-credit-application-step',
  WITHDRAWAL: 'WITHDRAWAL',
  modalOpen: (name: string) => `modal-open-${name}`,
  SE: {
    MONEEZY_BANNER_LOADED: 'moneezy-banner-loaded',
    MONEEZY_BANNER_CLICK: 'moneezy-banner-click'
  },
  UK: {
    CREDIT_APPROVED: 'uk-credit-approved',
    /**
     * This event name is used for Facebook Pixel conversion tracking in Segment, do not change
     * without updating in Segment
     * Ticket: https://fairloo.atlassian.net/browse/FCUK-1976
     */
    SUBMIT_CREDIT_APPLICATION: 'uk-submit-credit-application'
  }
} as const;
