import { ProductTierData } from './types';

type ProductTier =
  | '10k'
  | '15k'
  | '20k'
  | '25k'
  | '30k'
  | '35k'
  | '40k'
  | '45k'
  | '50k'
  | '55k'
  | '60k'
  | '70k';

// Note: This object is only to be used on static texts and legal documents. It should never be used in business logic.
const productTiersSECredit = {
  '10k': {
    amount: 10_000,
    interest: 29
  },
  '15k': {
    amount: 15_000,
    interest: 29
  },
  '20k': {
    amount: 20_000,
    interest: 29
  },
  '25k': {
    amount: 25_000,
    interest: 29
  },
  '30k': {
    amount: 30_000,
    interest: 29
  },
  '35k': {
    amount: 35_000,
    interest: 29
  },
  '40k': {
    amount: 40_000,
    interest: 28
  },
  '45k': {
    amount: 45_000,
    interest: 28
  },
  '50k': {
    amount: 50_000,
    interest: 26
  },
  '55k': {
    amount: 55_000,
    interest: 26
  },
  '60k': {
    amount: 60_000,
    interest: 24
  },
  '70k': {
    amount: 70_000,
    interest: 20
  }
} satisfies Record<ProductTier, Pick<ProductTierData, 'amount' | 'interest'>>;

export default productTiersSECredit;
